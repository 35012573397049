import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { PermitSteps } from "@stories/molecules/PermitSteps/PermitSteps";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import {
  PermitLineItem,
  createDefaultPermit,
} from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useState } from "react";
import * as React from "react";

import { PermitAdditionalInfoForm } from "../PermitForm/PermitAdditionalInfoForm";
import { PermitAddressForm } from "../PermitForm/PermitAddressForm";
import { PermitApplicantForm } from "../PermitForm/PermitApplicantForm";
import { PermitBuildingWorksForm } from "../PermitForm/PermitBuildingWorksForm";
import { PermitDAForm } from "../PermitForm/PermitDAForm";
import { PermitDetailsForm } from "../PermitForm/PermitDetailsForm";
import { PermitOwnerForm } from "../PermitForm/PermitOwnerForm";

interface PermitModalProps {
  open: boolean;
  organisation: OrganisationLineItem;
  profile: ProfileLineItem;
  project: ProjectLineItem;
  existingPermit?: PermitLineItem;
  permitCount: number;
  createPermit: (permits: PermitLineItem) => void;
  updatePermit: (permits: PermitLineItem) => void;
  handleClose: () => void;
}

export default function PermitModal({
  createPermit,
  existingPermit,
  handleClose,
  open,
  organisation,
  permitCount,
  profile,
  project,
  updatePermit,
}: PermitModalProps) {
  const [permit, setPermit] = useState<PermitLineItem>(
    existingPermit ||
      createDefaultPermit(organisation, profile, project, permitCount)
  );
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (permit: PermitLineItem) => {
    setPermit(permit);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async (permit: PermitLineItem) => {
    if (existingPermit) {
      await updatePermit(permit);
    } else {
      await createPermit(permit);
    }
    handleClose();
  };

  const selectedCreatePermitStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return <PermitDetailsForm permit={permit} handleSubmit={handleNext} />;
      case 1:
        return (
          <PermitBuildingWorksForm
            permit={permit}
            handleBack={handleBack}
            handleSubmit={handleNext}
          />
        );
      case 2:
        return (
          <PermitDAForm
            permit={permit}
            handleBack={handleBack}
            handleSubmit={handleNext}
          />
        );
      case 3:
        return (
          <PermitAddressForm
            permit={permit}
            handleBack={handleBack}
            handleSubmit={handleNext}
          />
        );
      case 4:
        return (
          <PermitApplicantForm
            permit={permit}
            handleBack={handleBack}
            handleSubmit={handleNext}
          />
        );
      case 5:
        return (
          <PermitOwnerForm
            permit={permit}
            handleBack={handleBack}
            handleSubmit={handleNext}
          />
        );
      case 6:
        return (
          <PermitAdditionalInfoForm
            permit={permit}
            handleBack={handleBack}
            handleSubmit={onSubmit}
          />
        );
    }
  })();

  const title = existingPermit ? "Edit Permit" : "Create Permit";
  const loading = false;
  const handleCloseDialog = (event: any, reason: string) => {
    console.log(event, reason);
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return; // Prevent closing on backdrop click or Escape key
    }
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseDialog}
      disableEscapeKeyDown
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 34px 25px" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 14,
            top: 11,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 20px 40px" }}>
        <PermitSteps activeStep={activeStep} loading={loading} />
        {selectedCreatePermitStep}
      </DialogContent>
    </Dialog>
  );
}
