import { getApiData, listApiData } from "@hooks/utils/api";
import { decodeOrganisation } from "permit-one-common/src/decoders/organisation";
import { Organisation } from "permit-one-common/src/entities/organisation";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import * as React from "react";

export const usePermitOneAdmin = () => {
  const [organisations, setOrganisations] = React.useState<
    OrganisationLineItem[]
  >([]);
  const [error, setError] = React.useState<string | null>(null);

  const [isPermitOneAdminLoading, setIsPermitOneAdminLoading] =
    React.useState<boolean>(true);

  const listOrganisations = async (): Promise<void> => {
    try {
      setIsPermitOneAdminLoading(true);
      const organisationsRes = await listApiData(
        "listOrganisationsForAdmin",
        "permitOneAdmin",
        ""
      );
      const organisationLineItems = organisationsRes.data.map((m) =>
        decodeOrganisation(m as any as Organisation)
      );

      setOrganisations(organisationLineItems);
    } catch (e: any) {
      setError("Could not list fees");
    } finally {
      setIsPermitOneAdminLoading(false);
    }
  };

  const joinOrganisation = async (id: string): Promise<void> => {
    try {
      setIsPermitOneAdminLoading(true);
      await getApiData("joinOrganisationAdmin", "permitOneAdmin", id);

      window.location.reload();
    } catch (e: any) {
      setError("Could not create permit fee");
    } finally {
      setIsPermitOneAdminLoading(false);
    }
  };

  React.useEffect(() => {
    listOrganisations();
  }, []);

  return {
    error,
    isPermitOneAdminLoading,
    joinOrganisation,
    organisations,
  };
};
