import { Grid } from "@mui/material";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";

import { Table } from "../Table/Table";

interface ReadOnlyDocumentTable2Props {
  loading: boolean;
  searchTerm: string;
  conditionDocuments: ConditionDocumentLineItem[];
}

export const ReadOnlyDocumentTable2 = ({
  conditionDocuments,
  loading,
  searchTerm,
}: ReadOnlyDocumentTable2Props) => {
  const colDefs = [
    {
      field: "documentTitle",
      flex: 5,
      headerName: "Title",
    },
    {
      field: "createdByName",
      flex: 2,
      headerName: "Submitter",
      valueFormatter: (params) => {
        return params.data.submitter;
      },
    },
    {
      field: "created",
      flex: 2,
      headerName: "Created",
      valueFormatter: (params) => {
        return params.data.created.toLocaleDateString();
      },
    },
  ] as (ColDef | ColGroupDef)[];

  return (
    <Grid item xs={12} md={12}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={conditionDocuments}
        searchValue={searchTerm}
        searchKeys={["documentTitle", "createdByName"]}
      />
    </Grid>
  );
};
