import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePermit } from "@hooks/crud/usePermit";
import { AddIcon } from "@stories/atoms/Icon/Icons";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import PermitModal from "@stories/organisms/PermitModal/PermitModal";
import { PermitSummary } from "@stories/organisms/Summary/PermitSummary";
import { PermitTable2 } from "@stories/organisms/Tables/PermitTable/PermitTable2";
import * as RouteHelper from "@utils/routes";
import { PermitStatus } from "permit-one-common/src/entities/permit";
import {
  PermitCountLineItem,
  PermitLineItem,
} from "permit-one-common/src/interfaces/permit";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Permits = (): JSX.Element => {
  const navigate = useNavigate();
  const { getProject, isProjectLoading, selectedProject } = useProjectContext();
  const { userOrganisation, userProfile } = useProfileContext();

  const { createPermit, isPermitLoading, permits, updatePermit } = usePermit(
    selectedProject?.id
  );

  const [selectedPermit, setSelectedPermit] = useState<
    PermitLineItem | undefined
  >(undefined);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedPermit(undefined);
  };
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleCreatePermit = useCallback(
    (permit: PermitLineItem) => {
      if (selectedProject) {
        createPermit(permit).then(() => {
          getProject(selectedProject.id);
        });
      }
    },
    [createPermit, getProject, selectedProject]
  );

  const handleUpdatePermit = useCallback(
    (permit: PermitLineItem) => {
      if (selectedProject) {
        updatePermit(permit).then(() => {
          getProject(selectedProject.id);
        });
      }
    },
    [getProject, selectedProject, updatePermit]
  );

  const handleEditPermit = useCallback((permit: PermitLineItem) => {
    setSelectedPermit(permit);
    handleOpen();
  }, []);

  const handleViewPermit = useCallback(
    (permit: PermitLineItem) => {
      if (selectedProject) {
        navigate(RouteHelper.conditions(selectedProject.id, permit.id));
      }
    },
    [selectedProject]
  );

  const handleDeletePermit = useCallback(
    async (permit: PermitLineItem) => {
      if (selectedProject) {
        await updatePermit({
          ...permit,
          status: PermitStatus.Archived,
        }).then(() => {
          getProject(selectedProject.id);
        });
      }
    },
    [selectedProject, permits]
  );

  // const handleDestroyPermit = useCallback(
  //   async (permit: PermitLineItem) => {
  //     if (selectedProject) {
  //       await updatePermit({
  //         ...permit,
  //         archived: true,
  //       }).then(() => {
  //         getProject(selectedProject.id);
  //       });
  //     }
  //   },
  //   [selectedProject, permits]
  // );

  const handleRestorePermit = useCallback(
    async (permit: PermitLineItem) => {
      if (selectedProject) {
        await updatePermit({
          ...permit,
          status: PermitStatus.Active,
        }).then(() => {
          getProject(selectedProject.id);
        });
      }
    },
    [permits, selectedProject]
  );

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const [tabIndex, setTabIndex] = useState<number>(parseInt(status || "0"));
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const loading = isPermitLoading || isProjectLoading;

  const tabs = [
    {
      label: "All",
    },
    {
      label: "Active",
    },
    {
      label: "Approved",
    },
    {
      label: "Archived",
    },
  ];
  const buttons: HeaderButtonConfig[] =
    userOrganisation && userOrganisation.id === selectedProject?.organisationId
      ? [
          {
            action: handleOpen,
            icon: <AddIcon variant="secondary" />,
            text: "Add Permit",
            type: HeaderButtonType.PRIMARY,
            width: 130,
          },
        ]
      : [];

  const filteredPermits = (() => {
    switch (tabIndex) {
      case 1:
        return permits.filter(
          (permit) => permit.status === PermitStatus.Active
        );
      case 2:
        return permits.filter(
          (permit) => permit.status === PermitStatus.Complete
        );
      case 3:
        return permits.filter(
          (permit) => permit.status === PermitStatus.Archived
        );
      default:
        return permits;
    }
  })();

  useEffect(() => {
    if (status) {
      setTabIndex(parseInt(status));
    }
  }, [status]);

  const permitCounts: PermitCountLineItem = {
    active: permits.filter(
      (value: PermitLineItem) => value.status === PermitStatus.Active
    ).length,
    approved: permits.filter(
      (value: PermitLineItem) => value.status === PermitStatus.Complete
    ).length,
    archived: permits.filter(
      (value: PermitLineItem) => value.status === PermitStatus.Archived
    ).length,
    inReview: permits.filter(
      (value: PermitLineItem) => value.status === PermitStatus.InReview
    ).length,
  };

  return (
    <PageContainer>
      <Header
        subTitle={
          `Manage all permits for ${selectedProject?.projectName}` ||
          "PermitOne Documents"
        }
        mainTitle={"Permits"}
        loading={loading}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        buttons={buttons}
      />
      <PermitSummary
        permitCounts={permitCounts}
        project={selectedProject}
        loading={loading}
      />
      <PermitTable2
        loading={loading}
        permits={filteredPermits}
        searchTerm={searchTerm}
        project={selectedProject}
        profile={userProfile}
        handleEditPermit={handleEditPermit}
        handleViewPermit={handleViewPermit}
        handleDeletePermit={handleDeletePermit}
        handleRestorePermit={handleRestorePermit}
      />
      {selectedProject && userOrganisation && userProfile && open ? (
        <PermitModal
          open={open}
          handleClose={handleClose}
          createPermit={handleCreatePermit}
          updatePermit={handleUpdatePermit}
          permitCount={permits.length}
          organisation={userOrganisation}
          profile={userProfile}
          project={selectedProject}
          existingPermit={selectedPermit}
        />
      ) : null}
    </PageContainer>
  );
};
