// third-party

// type
import FolderCopy from "@mui/icons-material/FolderCopy";
import Summarize from "@mui/icons-material/Summarize";
import { useTheme } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { NavItemType } from "types";

export const getWorkingNav = (accessId?: string): NavItemType => {
  const theme = useTheme();
  return {
    children: [
      {
        breadcrumbs: false,
        icon: <Summarize sx={{ color: theme.palette.primary.main }} />,
        id: "Permit",
        title: "Permit",
        type: "item",
        url: accessId ? RouteHelper.conditionPublic(accessId) : "",
      },
    ],
    icon: <FolderCopy sx={{ color: theme.palette.primary.main }} />,
    id: "working-nav",
    title: "Manage",
    type: "group",
  };
};
