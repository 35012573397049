import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useDocument } from "@hooks/crud/useDocument";
import { useDownloadDocuments } from "@hooks/crud/useDownloadDocuments";
import { usePermit } from "@hooks/crud/usePermit";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import BulkDocumentModal from "@stories/organisms/BulkDocumentModal/BulkDocumentModal";
import DocumentModal from "@stories/organisms/DocumentModal/DocumentModal";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { DocumentTable2 } from "@stories/organisms/Tables/DocumentTable/DocumentTable2";
import { ExportIcon } from "assets/constants";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { getPermitCode } from "permit-one-common/src/utils/string";
import { useCallback, useState } from "react";

export const Documents = (): JSX.Element => {
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();
  const { isProjectLoading, selectedProject } = useProjectContext();
  const [openBulk, setOpenBulk] = useState(false);
  const [openSingle, setOpenSingle] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<
    ConditionDocumentLineItem[]
  >([]);
  const [selectedDocument, setSelectedDocument] = useState<
    ConditionDocumentLineItem | undefined
  >(undefined);
  const [selectedPermit, setSelectedPermit] = useState<PermitLineItem>();
  const { downloadProgress, downloadStep, getDocumentsZip } =
    useDownloadDocuments();

  const handleSetSelectedDocuments = useCallback(
    (documents: ConditionDocumentLineItem[]) => {
      setSelectedDocuments(documents);
    },
    []
  );

  const handleDownloadAllDocuments = useCallback(async () => {
    if (selectedProject && selectedDocuments.length > 0) {
      await getDocumentsZip(
        selectedProject.id,
        selectedDocuments.map((d) => d.id)
      );
    }
  }, [selectedProject, selectedDocuments]);

  const { isPermitLoading, permits } = usePermit(selectedProject?.id);

  const {
    createDocuments,
    deleteDocument,
    documents,
    isDocumentLoading,
    updateDocuments,
  } = useDocument(selectedProject?.id);

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleOptionChange = async (id?: string) => {
    if (id) {
      setSelectedPermit(permits.find((permit) => permit.id === id));
    } else {
      setSelectedPermit(undefined);
    }
  };

  const handleCloseSingle = () => {
    setOpenSingle(false);
    setSelectedDocument(undefined);
  };

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenSingle = (conditionDocument: ConditionDocumentLineItem) => {
    setSelectedDocument(conditionDocument);
    setOpenSingle(true);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
  };

  const handleDeleteDocument = (document: ConditionDocumentLineItem) => {
    deleteDocument(document);
  };

  const loading =
    isAuthProfileLoading ||
    isProjectLoading ||
    isPermitLoading ||
    isDocumentLoading;
  handleDownloadAllDocuments;

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleDownloadAllDocuments,
      icon: <ExportIcon variant="secondary" />,
      text: "Download Documents",
      type: HeaderButtonType.PRIMARY,
      width: 180,
    },
  ];

  const dropdownOptions = permits.map((permit) => ({
    id: permit.id,
    name: `${permit.permitName} (${selectedProject?.projectCode} - ${
      permit ? getPermitCode(permit) : ""
    })`,
  }));

  const filteredDocuments = documents.filter((document) => {
    if (selectedPermit) return document.permitId === selectedPermit.id;
    else return document;
  });
  return (
    <PageContainer>
      <Header
        subTitle={
          `View all documents for ${selectedProject?.projectName}` ||
          "PermitOne Documents"
        }
        mainTitle={"Documents"}
        loading={loading}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        buttons={buttons}
        options={dropdownOptions}
        handleOptionChange={handleOptionChange}
      />
      <DocumentTable2
        handleSetSelectedDocuments={handleSetSelectedDocuments}
        selectedDocuments={selectedDocuments}
        loading={loading}
        permits={permits}
        organisation={userOrganisation}
        profile={userProfile}
        searchTerm={searchTerm}
        handleOpenBulk={handleOpenBulk}
        handleEditDocument={handleOpenSingle}
        handleDeleteDocument={handleDeleteDocument}
        documents={filteredDocuments}
      />
      {userProfile && selectedProject ? (
        <BulkDocumentModal
          open={openBulk}
          userProfile={userProfile}
          project={selectedProject}
          handleClose={handleCloseBulk}
          createDocuments={createDocuments}
          documentCount={documents.length}
        />
      ) : null}
      {userProfile && selectedProject && selectedDocument ? (
        <DocumentModal
          open={openSingle}
          project={selectedProject}
          userProfile={userProfile}
          handleClose={handleCloseSingle}
          updateDocuments={updateDocuments}
          documentCount={documents.length}
          existingDocument={selectedDocument}
        />
      ) : null}
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Exporting Documents Zip"}
      />
    </PageContainer>
  );
};
