import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton, Skeleton, Stack } from "@mui/material";

import { StyledSubHeading, StyledTitle } from "./components";

interface PageHeadingProps {
  subHeading?: string;
  title: string;
  loading: boolean;
  handleBack?: () => void;
}

export const PageHeading = ({
  handleBack,
  loading,
  subHeading,
  title,
}: PageHeadingProps) => {
  if (loading) {
    return (
      <Stack direction="row" spacing={2}>
        {handleBack ? (
          <Skeleton variant="circular" animation="wave">
            <IconButton
              data-test-id="back-button"
              size="large"
              sx={{
                color: "black",
                height: "auto",
                padding: "5px",
                width: "auto",
              }}
            >
              <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Skeleton>
        ) : null}
        <Stack direction="column" gap={1}>
          {title.length ? (
            <Skeleton animation="wave">
              <StyledTitle variant="h2">Loading...</StyledTitle>
            </Skeleton>
          ) : null}
          {subHeading?.length ? (
            <Skeleton animation="wave">
              <StyledTitle variant="h2">Loading...</StyledTitle>
            </Skeleton>
          ) : null}
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing={2}>
      {handleBack ? (
        <IconButton
          data-test-id="back-button"
          disableRipple
          size="large"
          onClick={handleBack}
          sx={{
            color: "black",
            height: "auto",
            padding: "5px",
            width: "auto",
          }}
        >
          <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
        </IconButton>
      ) : null}
      <Stack direction="column" gap={1}>
        <StyledTitle variant="h2">{title}</StyledTitle>
        <StyledSubHeading paragraph>{subHeading || ""}</StyledSubHeading>
      </Stack>
    </Stack>
  );
};
