import { Grid, Stack, styled } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { AddIcon } from "assets/constants";
import {
  OrganisationLineItem,
  paymentPlanToString,
} from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

import { Table } from "../Table/Table";
import { ActionCell, CellAction, defaultCellConfig } from "../Table/TableCells";

interface GodModeTableProps {
  profile?: ProfileLineItem;
  organisations: OrganisationLineItem[];
  searchTerm: string;
  loading: boolean;
  handleJoinOrganisation: (org: OrganisationLineItem) => void;
}

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

export const GodModeTable = ({
  handleJoinOrganisation,
  loading,
  organisations,
  profile,
  searchTerm,
}: GodModeTableProps) => {
  const colDefs = [
    {
      autoHeight: true,
      cellStyle: {
        ...defaultCellConfig.cellStyle,
        textWrap: "auto",
      },
      field: "organisationName",
      flex: 4,
      headerName: "Organisation Name",
    },
    {
      field: "planType",
      flex: 2,
      headerName: "Plan Type",
      valueFormatter: (params: any) => {
        return paymentPlanToString(params.data.planType);
      },
    },
    {
      cellRenderer: ActionCell,
      field: "action",
      flex: 1,
      headerName: "",
    },
  ] as ColDef[];

  const cellActions = [
    {
      action: handleJoinOrganisation,
      icon: <AddIcon />,
      title: "Join Organisations",
      validateEnabled: (data: OrganisationLineItem) =>
        profile?.organisationId !== data.id,
    },
  ] as CellAction[];

  return (
    <Grid item xs={12} md={12} mt={2}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Table
            columnDefs={colDefs}
            loading={loading}
            data={organisations}
            searchValue={searchTerm}
            context={{
              cellActions,
              profile,
            }}
            searchKeys={["organisationName"]}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
