import { getApiData } from "@hooks/utils/api";
import { downloadFile } from "@hooks/utils/useUpload";
import { decodeFileGeneration } from "permit-one-common/src/decoders/fileGeneration";
import {
  FileGeneration,
  FileGenerationStatus,
} from "permit-one-common/src/entities/fileGeneration";
import { FileGenerationLineItem } from "permit-one-common/src/interfaces/fileGeneration";
import { sleep } from "permit-one-common/src/utils/sleep";
import { getFileNameFromUrl } from "permit-one-common/src/utils/string";
import * as React from "react";
import { v4 as uuid } from "uuid";

export const useExcel = () => {
  const [downloadStepExcel, setDownloadStepExcel] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [excelDownloadProgress, setExcelDownloadProgress] =
    React.useState<number>(0);

  const [error, setError] = React.useState<string | null>(null);
  const getExcelReport = async (projectId: string, permitId: string) => {
    try {
      const fileGenerationId = uuid();
      setExcelDownloadProgress(0);
      setDownloadStepExcel(FileGenerationStatus.Fetching);

      let fileGenerationResult: FileGenerationLineItem | undefined = undefined;
      let counter = 0;
      while (counter < 300) {
        const profileResult = await getApiData(
          "getXlsReport",
          "fileGeneration",
          fileGenerationId,
          {
            permitId,
            projectId,
          }
        );
        fileGenerationResult = decodeFileGeneration(
          profileResult.data as FileGeneration
        );

        setDownloadStepExcel(fileGenerationResult.status);
        if (fileGenerationResult.status === FileGenerationStatus.Error) {
          setError("Could not fetch pdf report");
          break;
        } else if (
          fileGenerationResult.status === FileGenerationStatus.Generated
        ) {
          break;
        }
        await sleep(1000);
        counter++;
      }

      if (fileGenerationResult && fileGenerationResult.url) {
        await downloadFile(
          fileGenerationResult.url,
          getFileNameFromUrl(fileGenerationResult.url),
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;

            setExcelDownloadProgress(total);
            if (total >= 100) {
              setDownloadStepExcel(FileGenerationStatus.Idle);
            }
          }
        );
      } else {
        setError("Could not fetch pdf report");
        setDownloadStepExcel(FileGenerationStatus.Error);
      }
    } catch (e) {
      console.log(e);
      setError("Could not fetch pdf report");
      setDownloadStepExcel(FileGenerationStatus.Error);
    } finally {
      setDownloadStepExcel(FileGenerationStatus.Idle);
    }
  };

  return {
    downloadStepExcel,
    error,
    excelDownloadProgress,
    getExcelReport,
  };
};
