import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  TextField,
  Autocomplete,
  IconButton,
  Skeleton,
  InputAdornment,
} from "@mui/material";
import { SearchButtonIcon } from "assets/constants";
import { useState } from "react";

export interface DropDownOption {
  id: string;
  name: string;
}

interface HeaderDropdownProps {
  loading: boolean;
  options: DropDownOption[];
  handleOptionChange: (id?: string) => void;
}

export const HeaderDropdown = ({
  handleOptionChange,
  loading,
  options,
}: HeaderDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<DropDownOption | null>(
    null
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <Box
          sx={{
            width: {
              sm: "48px",
              xs: "100%",
            },
          }}
          className="search-container"
        >
          <TextField
            className="search-box"
            variant="standard"
            placeholder="Search"
            disabled={loading}
            fullWidth
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  className="icon"
                  position="start"
                  sx={{
                    margin: "0 0 0 0",
                    p: 0,
                  }}
                >
                  <SearchButtonIcon />
                </InputAdornment>
              ),
              style: {
                backgroundColor: "white",
                borderColor: "#D5D7DA",
                fontSize: "14px",
                lineHeight: "20px",
                opacity: "100%",
                outline: "none",
              },
            }}
            sx={{
              "& .MuiInputBase-input": {
                alignItems: "center",
                borderRadius: "0px !important",
                color: "#717680",
                fontFamily: '"Inter", sans-serif',
                fontSize: "14px",
                height: "auto",
                lineHeight: "20px",
                ml: 1,

                padding: "0px",
              },

              bgcolor: "white",
              border: "1px solid #D5D7DA",
              borderRadius: "8px",
              boxShadow: "0px 1px 2px 0px #0A0D120D",
              padding: "8px 14px 8px 12px",
            }}
          />
        </Box>
      </Skeleton>
    );
  }
  return (
    <Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
      <Box
        sx={{
          transition: "width 0.3s ease",
          width: dropdownOpen || selectedOption ? "300px" : "48px",
        }}
        onMouseEnter={() => setDropdownOpen(true)}
        onMouseLeave={() => !selectedOption && setDropdownOpen(false)}
      >
        {dropdownOpen || selectedOption ? (
          <Autocomplete
            loading={loading}
            options={options}
            value={selectedOption}
            getOptionLabel={(option) => option.name} // Add this line
            onChange={(_, newValue) => {
              setSelectedOption(newValue);
              handleOptionChange(newValue?.id);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select"
                sx={{
                  bgcolor: "white",
                  border: "1px solid #D5D7DA",
                  borderRadius: "8px",
                  boxShadow: "0px 1px 2px 0px #0A0D120D",
                  padding: "8px 14px",
                }}
              />
            )}
          />
        ) : (
          <IconButton
            onClick={() => setDropdownOpen(true)}
            sx={{ backgroundColor: "white" }}
          >
            <FilterListIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
