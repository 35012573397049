import EditIcon from "@mui/icons-material/Edit";
import {
  Table as MUITable,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { ColDef } from "ag-grid-community";

import { generateTableHead } from "./Table";

type Props = {
  colDefs: ColDef[];
  noFullTableBorder?: boolean;
};

const styles = (noFullTableBorder: boolean) => ({
  border: `${noFullTableBorder ? "none" : "1px solid #E9EAEB"}`,
  borderBottomLeftRadius: `${noFullTableBorder ? "12px" : "12px"}`,
  borderBottomRightRadius: `${noFullTableBorder ? "12px" : "12px"}`,
  borderCollapse: "unset !important",
  borderColor: "rgb(233, 234, 235)",
  borderTop: `${noFullTableBorder ? "1px solid #E9EAEB" : "1px solid #E9EAEB"}`,
  borderTopLeftRadius: `${noFullTableBorder ? "0" : "12px"}`,
  borderTopRightRadius: `${noFullTableBorder ? "0" : "12px"}`,
});

export const ProjectRowSkeleton = () => {
  const skeletonCells = [
    { width: "10%" },
    { width: "20%" },
    { width: "20%" },
    { width: "20%" },
    { width: "20%" },
  ];

  const tableId = Date.now();
  return (
    <TableRow>
      {skeletonCells.map((cell, index) => (
        <TableCell
          key={`${tableId}-${index}`}
          sx={{
            paddingBottom: "15px",
            paddingTop: "15px",
            width: cell.width,
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
      <TableCell>
        <Stack direction="row" justifyContent={"center"}>
          <Skeleton animation="wave" variant="circular">
            <EditIcon />
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

interface BlankSkeletonRowProps {
  colDefs: ColDef[];
}

export const BlankSkeletonRow = ({ colDefs }: BlankSkeletonRowProps) => {
  const sumFlex = colDefs.reduce((acc, cell) => {
    return acc + (cell?.flex || 0);
  }, 0);

  return (
    <TableRow>
      {colDefs.map((cell, index) => {
        if (cell.field === "action") {
          return (
            <TableCell key={index}>
              <Stack direction="row" justifyContent={"center"}>
                <Skeleton animation="wave" variant="circular">
                  <EditIcon />
                </Skeleton>
              </Stack>
            </TableCell>
          );
        }
        return (
          <TableCell
            key={index}
            sx={{
              paddingBottom: "15px",
              paddingTop: "15px",
              width: `${((cell?.flex || 0) / sumFlex) * 100}%`,
            }}
          >
            <Skeleton animation="wave" />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const TableSkeleton = ({ colDefs, noFullTableBorder = false }: Props) => {
  const tableHead = generateTableHead(colDefs);

  return (
    <MUITable sx={styles(noFullTableBorder)}>
      <ListTableHead
        loading
        headLabel={tableHead}
        rowCount={0}
        numSelected={0}
      />
      <TableBody>
        {Array.from({ length: 8 }, (_, index) => (
          <BlankSkeletonRow key={index} colDefs={colDefs} />
        ))}
      </TableBody>
    </MUITable>
  );
};

export const ConditionTableSkeletonHead = ({
  colDefs,
  noFullTableBorder = false,
}: Props) => {
  const tableHead = generateTableHead(colDefs);

  return (
    <MUITable sx={styles(noFullTableBorder)}>
      <ListTableHead
        loading
        headLabel={tableHead}
        rowCount={0}
        numSelected={0}
      />
      <TableBody>
        {Array.from({ length: 8 }, (_, index) => (
          <BlankSkeletonRow key={index} colDefs={colDefs} />
        ))}
      </TableBody>
    </MUITable>
  );
};

export const ConditionTableSkeletonHeaderRow = () => {
  return (
    <TableHead>
      <TableRow>
        {Array.from({ length: 5 }).map((_, index) => (
          <TableCell
            key={index}
            component={"th"}
            sx={{
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
          >
            <Skeleton animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ConditionTableSkeletonRow = () => {
  return (
    <TableRow>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableCell
          key={index}
          sx={{
            paddingBottom: "15px",
            paddingTop: "15px",
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
};

interface ConditionTableSkeletonProps {
  noFullTableBorder?: boolean;
}
export const ConditionTableSkeleton = ({
  noFullTableBorder = false,
}: ConditionTableSkeletonProps) => {
  return (
    <MUITable sx={styles(noFullTableBorder)}>
      <ConditionTableSkeletonHeaderRow />
      <TableBody>
        {Array.from({ length: 8 }, (_, index) => (
          <ConditionTableSkeletonRow key={index} />
        ))}
      </TableBody>
    </MUITable>
  );
};

export default TableSkeleton;
