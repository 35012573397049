import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import {
  decodeProject,
  encodeProject,
} from "permit-one-common/src/decoders/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import {
  Project,
  ProjectLineItem,
} from "permit-one-common/src/interfaces/project";
import { useCallback, useEffect, useState } from "react";

export const useProject = (userProfile?: ProfileLineItem) => {
  const [selectedProject, setSelectedProject] = useState<ProjectLineItem>();
  const [projects, setProjects] = useState<ProjectLineItem[]>([]);
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const getProject = useCallback(async (id: string): Promise<void> => {
    try {
      setIsProjectLoading(true);
      const project = (await getApiData("getProject", "project", id))
        .data as Project;
      if (project) {
        const decodedProject = await decodeProject(project, getImageUrl);
        setSelectedProject(decodedProject);
      } else {
        setSelectedProject(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch project");
    } finally {
      setIsProjectLoading(false);
    }
  }, []);

  const listProjects = useCallback(async (profileId: string): Promise<void> => {
    try {
      setIsProjectLoading(true);

      const projectRes = await listApiData(
        "listProjects",
        "project",
        profileId
      );

      const decodedProjects = await Promise.all(
        projectRes.data.map((p) => decodeProject(p as Project, getImageUrl))
      );
      setProjects(decodedProjects);
    } catch (e: any) {
      setError("Could not list projects");
    } finally {
      setIsProjectLoading(false);
    }
  }, []);

  const createProject = useCallback(
    async (project: ProjectLineItem): Promise<void> => {
      try {
        setIsProjectLoading(true);
        (await postApiData("createProject", "project", encodeProject(project)))
          .data as Project;
        setProjects([project, ...projects]);
      } catch (e: any) {
        setError("Could not list projects");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects]
  );

  const updateProject = useCallback(
    async (project: ProjectLineItem): Promise<void> => {
      try {
        setIsProjectLoading(true);
        (await postApiData("updateProject", "project", encodeProject(project)))
          .data as Project;
        setProjects(
          projects.map((c) => {
            if (c.id === project.id) {
              return project;
            }
            return c;
          })
        );

        if (selectedProject?.id === project.id) {
          setSelectedProject(() => project);
        }
      } catch (e: any) {
        setError("Could not update project");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects, selectedProject]
  );

  const deleteProject = useCallback(
    async (project: ProjectLineItem): Promise<void> => {
      try {
        setIsProjectLoading(true);
        (await postApiData("deleteProject", "project", encodeProject(project)))
          .data as Project;
        setProjects(projects.filter((p) => p.id !== project.id));
      } catch (e: any) {
        setError("Could not delete project");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects]
  );

  useEffect(() => {
    if (userProfile) {
      listProjects(userProfile.id);
    }
  }, [userProfile, listProjects]);

  return {
    createProject,
    deleteProject,
    error,
    getProject,
    isProjectLoading,
    listProjects,
    projects,
    selectedProject,
    setSelectedProject,
    updateProject,
  };
};
