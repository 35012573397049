import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import DashboardCard from "@stories/molecules/DashboardCard/DashboardCard";
import { PermitMultiProgressBar } from "@stories/molecules/PermitMultiProgressBar/PermitMultiProgressBar";
import * as RouteHelper from "@utils/routes";
import { PermitCountLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useNavigate } from "react-router-dom";

interface PermitSummaryProps {
  permitCounts?: PermitCountLineItem;
  project?: ProjectLineItem;
  loading: boolean;
  showHeader?: boolean;
}
export const PermitSummary = ({
  loading,
  permitCounts,
  project,
  showHeader,
}: PermitSummaryProps) => {
  const navigate = useNavigate();

  const activePermits = permitCounts?.active || 0;
  const archivedPermits = permitCounts?.archived || 0;
  const approvedPermits = permitCounts?.approved || 0;
  const allPermits = activePermits + archivedPermits + approvedPermits;
  return (
    <>
      {showHeader && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "calc(100% - 538px)" }}>
            <Stack direction="row" spacing={1}>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography
                    sx={{
                      color: "rgb(31, 35, 40) !important",
                      fontSize: "19px",
                      fontWeight: 700,
                      margin: "0 0 10px",
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Permit Status{" "}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  sx={{
                    color: "rgb(31, 35, 40) !important",
                    fontSize: "19px",
                    fontWeight: 700,
                    margin: "0 0 10px",
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  Permit Status{" "}
                </Typography>
              )}

              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography
                    sx={{
                      color: "rgb(31, 35, 40) !important",
                      fontSize: "19px",
                      fontWeight: 700,
                      margin: "0 0 10px",
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <span className="task-number">(199)</span>
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  sx={{
                    color: "rgb(31, 35, 40) !important",
                    fontSize: "19px",
                    fontWeight: 700,
                    margin: "0 0 10px",
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  <span className="task-number">({allPermits})</span>
                </Typography>
              )}
            </Stack>

            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography
                  sx={{
                    color: "rgb(101, 109, 118) !important",
                    fontSize: "14px",
                    fontWeight: 500,
                    margin: "-5px 0 30px",
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  Summary of all Project Permits.
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                sx={{
                  color: "rgb(101, 109, 118) !important",
                  fontSize: "14px",
                  fontWeight: 500,
                  margin: "0 0 30px",
                }}
                color="text.secondary"
                gutterBottom
              >
                Summary of all Project Permits.
              </Typography>
            )}
          </div>
          <PermitMultiProgressBar
            loading={loading}
            assigned={activePermits}
            assignedText={"Active"}
            archived={archivedPermits}
            archivedText={"Archived"}
            approved={approvedPermits}
            approvedText={"Approved"}
          />
        </div>
      )}
      <Box
        className="dashb-cards"
        flexGrow="1"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="assigned"
          >
            <DashboardCard
              loading={loading}
              title={"Active"}
              subTitle={activePermits.toLocaleString()}
              desc={"Current permits requiring action and approval"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.permits(project.id)}?status=1`);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="approved"
          >
            <DashboardCard
              loading={loading}
              title={"Approved"}
              subTitle={approvedPermits.toLocaleString()}
              desc={"Permits approved with no further action required"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.permits(project.id)}?status=2`);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="overdue"
          >
            <DashboardCard
              loading={loading}
              title={"Archived"}
              subTitle={archivedPermits.toLocaleString()}
              desc={"Permits that have been archived"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.permits(project.id)}?status=3`);
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
