import { Menu, styled } from "@mui/material";

interface Props {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  children: React.ReactNode[];
}

const StyledMenu = styled(Menu)({
  "& .MuiList-root": { padding: 0 },
  "& .MuiMenu-paper ul li": {
    margin: 0,
    padding: 0,
  },
  "& .MuiMenu-paper ul li:focus": {
    backgroundColor: "transparent !important",
  },
  "& .MuiMenu-paper ul li:hover": {
    backgroundColor: "transparent !important",
    borderRadius: 0,
  },
});

const slotPropsStyles = {
  border: "1px solid #E9EAEB",
  borderRadius: "10px",
  boxShadow: "0px 1px 2px 0px #0A0D120D",
  mt: 0.5,
  overflow: "visible",
  padding: "12px",
};

const CustomMenu = ({ anchorEl, children, handleClose, open }: Props) => {
  return (
    <StyledMenu
      className="actionlist"
      disableScrollLock
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: slotPropsStyles,
        },
      }}
    >
      {children}
    </StyledMenu>
  );
};

export default CustomMenu;
