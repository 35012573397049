//Public routes
export const home = "/";
export const pricing = "/pricing";
export const signUp = "/sign-up";
export const login = "/login";
export const forgotPassword = "/forgot-password";
export const verify = "/verify";
export const demo = "/request-demo";
export const reportingAndAnalytics = "/permit-management";
export const smartDirectory = "/communication-portal";
export const apiIntegration = "/document-control";
export const implementation = "/reporting-and-analytics";
export const blog = "/blog";
export const partnership = "/api-integration";
export const definitions = "/templates";
export const whoWeare = "/our-team";
export const support = "/support";
export const privacyPolicy = "/privacy-policy";
export const termsConditions = "/terms-conditions";
export const conditionPublic = (accessId: string) =>
  absolute(["condition-external", accessId]);
//Private routes
export const listProjects = "/list-projects";
export const users = "/users";

export const godMode = "/god-mode";

export const blogDetail = (title: string) => absolute(["blog", title]);

export const overview = (projectId: string) =>
  absolute(["overview", projectId]);
export const directory = (projectId: string) =>
  absolute(["directory", projectId]);
export const projectGlobalDirectory = (projectId: string) =>
  absolute(["global-directory", projectId]);
export const globalDirectory = "/global-directory";
export const documents = (projectId: string) =>
  absolute(["documents", projectId]);
export const inbox = (projectId: string) => absolute(["inbox", projectId]);
export const permits = (projectId: string) => absolute(["permits", projectId]);
export const tasks = (projectId: string) => absolute(["tasks", projectId]);
export const allConditions = (projectId: string) =>
  absolute(["all-conditions", projectId]);

export const conditions = (
  projectId: string,
  permitId: string,
  conditionId?: string
) => absolute(["conditions", projectId, permitId, conditionId]);

export const condition = (
  projectId: string,
  permitId: string,
  conditionId: string,
  tab?: string
) =>
  absolute([
    "condition",
    projectId,
    permitId,
    conditionId,
    tab || "properties",
  ]);

export const mail = (
  projectId: string,
  permitId?: string,
  conditionId?: string,
  mailId?: string
) => absolute(["mail", projectId, permitId, conditionId, mailId]);

export const userAccount = "/user-account";
export const businessAccount = "/business-account";
export const createAccount = "/create-account";
export const acceptInvite = "/accept-invite";

export const editProject = (projectId: string) =>
  absolute(["edit-project", projectId]);

export const absolute = (params: (string | number | undefined)[]): string =>
  `/${params.filter((a) => !!a).join("/")}`;
