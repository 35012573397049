import { getPublicImageUrl } from "@hooks/utils/useUpload";
import { encodeCondition } from "permit-one-common/src/decoders/condition";
import { encodeConditionComment } from "permit-one-common/src/decoders/conditionComment";
import { encodeConditionDocument } from "permit-one-common/src/decoders/conditionDocument";
import { decodeFileGeneration } from "permit-one-common/src/decoders/fileGeneration";
import { decodePublicCondition } from "permit-one-common/src/decoders/publicCondition";
import {
  FileGeneration,
  FileGenerationStatus,
} from "permit-one-common/src/entities/fileGeneration";
import { PublicCondition } from "permit-one-common/src/entities/publicCondition";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { FileGenerationLineItem } from "permit-one-common/src/interfaces/fileGeneration";
import { PublicConditionLineItem } from "permit-one-common/src/interfaces/publicCondition";
import { getMimeType } from "permit-one-common/src/utils/mime";
import { sleep } from "permit-one-common/src/utils/sleep";
import { getFileNameFromUrl } from "permit-one-common/src/utils/string";
import * as React from "react";
import { toast, ToastOptions } from "react-toastify";
import { v4 as uuid } from "uuid";

const publicEndpoint =
  "https://nn95jitwp4.execute-api.ap-southeast-2.amazonaws.com/dev/signIn";

export const usePublicConditions = (accessId?: string) => {
  const [publicConditionPageModel, setPublicConditionPageModel] =
    React.useState<PublicConditionLineItem>();
  const [isConditionLoading, setIsConditionLoading] =
    React.useState<boolean>(true);
  const [isConditionUpdating, setIsConditionUpdating] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const [downloadStepPDF, setDownloadStepPDF] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [PDFDownloadProgress, setPDFDownloadProgress] =
    React.useState<number>(0);

  const [downloadStepExcel, setDownloadStepExcel] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [excelDownloadProgress, setExcelDownloadProgress] =
    React.useState<number>(0);

  const [downloadStepAllFiles, setDownloadStepAllFiles] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [allFilesDownloadProgress, setAllFilesDownloadProgress] =
    React.useState<number>(0);

  const getPublicConditonModel = React.useCallback(
    async (id: string, isUpdate = false): Promise<void> => {
      try {
        if (isUpdate) {
          setIsConditionUpdating(true);
        } else {
          setIsConditionLoading(true);
        }
        const res = await fetch(publicEndpoint, {
          body: JSON.stringify({
            accessId: id,
            callFn: "getPublicConditonModel",
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (res) {
          const content = await res.json();
          const conditionLineItem = await decodePublicCondition(
            content as PublicCondition,
            getPublicImageUrl
          );
          setPublicConditionPageModel(() => conditionLineItem);
        } else {
          setPublicConditionPageModel(() => undefined);
        }
      } catch (e: any) {
        setError("Could not fetch public condition");
      } finally {
        if (isUpdate) {
          setIsConditionUpdating(false);
        } else {
          setIsConditionLoading(false);
        }
      }
    },
    [
      setIsConditionLoading,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );

  const createDocuments = React.useCallback(
    async (conditionDocuments: ConditionDocumentLineItem[]) => {
      try {
        if (publicConditionPageModel) {
          setIsConditionUpdating(true);
          const res = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "createDocuments",
              documents: conditionDocuments.map((d) =>
                encodeConditionDocument(d)
              ),
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          if (res.ok) {
            console.log("Create documents.");
          } else {
            console.error("Create documents.");
          }
          setPublicConditionPageModel((prev) => {
            if (!prev) {
              return prev;
            }
            const newDocuments = [...prev.documents, ...conditionDocuments];
            return {
              ...prev,
              documents: newDocuments,
            };
          });
        }
      } catch (e: any) {
        setError("Could not create document");
        toast("Could not create document!", {
          type: "error",
        } as ToastOptions);
      } finally {
        setIsConditionUpdating(false);
      }
    },
    [
      publicConditionPageModel,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );
  const updateDocuments = React.useCallback(
    async (conditionDocuments: ConditionDocumentLineItem[]) => {
      try {
        if (publicConditionPageModel) {
          setIsConditionUpdating(true);
          const res = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "updateDocument",
              documents: conditionDocuments.map((d) =>
                encodeConditionDocument(d)
              ),
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          if (res.ok) {
            console.log("Update documents.");
          } else {
            console.error("Update documents.");
          }
          setPublicConditionPageModel((prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              documents: publicConditionPageModel.documents.map((d) => {
                const matchingDocument = conditionDocuments.find(
                  (cd) => cd.id === d.id
                );
                return matchingDocument || d;
              }),
            };
          });
        }
      } catch (e: any) {
        setError("Could not update document");
        toast("Could not update document!", {
          type: "error",
        } as ToastOptions);
      } finally {
        setIsConditionUpdating(false);
      }
    },
    [
      publicConditionPageModel,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );

  const deleteDocument = React.useCallback(
    async (conditionDocument: ConditionDocumentLineItem) => {
      try {
        setIsConditionUpdating(true);
        if (publicConditionPageModel) {
          const res = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "deleteDocument",
              document: encodeConditionDocument(conditionDocument),
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          if (res.ok) {
            console.log("Delete document.");
          } else {
            console.error("Delete document.");
          }

          setPublicConditionPageModel((prev?: PublicConditionLineItem) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              documents: publicConditionPageModel.documents.filter(
                (c) => c.id !== conditionDocument.id
              ),
            };
          });
        }
      } catch (e: any) {
        setError("Could not delete document");
        toast("Could not delete document!", {
          type: "error",
        } as ToastOptions);
      } finally {
        setIsConditionUpdating(false);
      }
    },
    [
      publicConditionPageModel,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );

  const createComments = React.useCallback(
    async (conditionComments: ConditionCommentLineItem[]) => {
      try {
        setIsConditionUpdating(true);
        if (publicConditionPageModel) {
          const res = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "createComments",
              comments: conditionComments.map((c) => encodeConditionComment(c)),
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          if (res.ok) {
            console.log("Create comments.");
          } else {
            console.error("Create comments.");
          }

          setPublicConditionPageModel((prev) => {
            if (!prev) {
              return prev;
            }
            const newComments = [...conditionComments, ...prev.comments];

            return {
              ...prev,
              comments: publicConditionPageModel.comments.map((d) => {
                const matchingDocument = newComments.find(
                  (cd) => cd.id === d.id
                );
                return matchingDocument || d;
              }),
            };
          });
        }
      } catch (e: any) {
        setError("Could not create comments");
        toast("Could not create comments!", {
          type: "error",
        } as ToastOptions);
      } finally {
        setIsConditionUpdating(false);
      }
    },
    [
      publicConditionPageModel,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );
  const deleteComment = React.useCallback(
    async (conditionComment: ConditionCommentLineItem) => {
      try {
        setIsConditionUpdating(true);
        if (publicConditionPageModel) {
          const res = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "deleteComment",
              comments: encodeConditionComment(conditionComment),
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          if (res.ok) {
            console.log("Delete comment.");
          } else {
            console.error("Delete comment.");
          }

          setPublicConditionPageModel((prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              comments: publicConditionPageModel.comments.filter(
                (c) => c.id !== conditionComment.id
              ),
            };
          });
        }
      } catch (e: any) {
        setError("Could not delete comment");
        toast("Could not delete comment!", {
          type: "error",
        } as ToastOptions);
      } finally {
        setIsConditionUpdating(false);
      }
    },
    [
      publicConditionPageModel,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );

  const updateCondition = React.useCallback(
    async (condition: ConditionLineItem): Promise<void> => {
      try {
        setIsConditionUpdating(true);

        const res = await fetch(publicEndpoint, {
          body: JSON.stringify({
            callFn: "updateCondition",
            condition: encodeCondition(condition),
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });
        condition.messageData = undefined;

        if (res.ok) {
          setPublicConditionPageModel((prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              conditions: prev.conditions.map((c) =>
                c.id == condition.id ? condition : c
              ),
            };
          });
        } else {
          throw new Error("Could not update condition");
        }
      } catch (e: any) {
        setError("Could not update condition");
        toast("Could not update condition!", {
          type: "error",
        } as ToastOptions);
      } finally {
        setIsConditionUpdating(false);
      }
    },
    [
      publicConditionPageModel,
      setIsConditionUpdating,
      setPublicConditionPageModel,
      setError,
    ]
  );

  const uploadDocument = React.useCallback(
    async (
      location: string,
      file: File,
      onProgress: (progress: { loaded: number; total: number }) => void,
      onError?: (error: any) => void
    ) => {
      const chunkSize = 5 * 1024 * 1024; // 5MB per part
      const totalParts = Math.ceil(file.size / chunkSize);
      try {
        const mimeType = getMimeType(file.name);
        // Step 1: Get presigned URLs and upload ID from backend
        const presignedResponse = await fetch(publicEndpoint, {
          body: JSON.stringify({
            callFn: "initiateMultipartUpload",
            mimeType,
            totalParts,
            uploadKey: location,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (!presignedResponse.ok) {
          throw new Error("Failed to get presigned URLs");
        }

        const { presignedUrls, uploadId } = await presignedResponse.json();

        // Step 2: Upload each part using presigned URLs
        const uploadedParts: { ETag: string; PartNumber: number }[] = [];

        for (let partNumber = 0; partNumber < totalParts; partNumber++) {
          const start = partNumber * chunkSize;
          const end = Math.min(start + chunkSize, file.size);
          const partBlob = file.slice(start, end);
          const presignedUrl = presignedUrls[partNumber];

          const uploadResponse = await fetch(presignedUrl, {
            body: partBlob,
            headers: {
              "Content-Type": "application/octet-stream",
            },
            method: "PUT",
          });

          if (!uploadResponse.ok) {
            throw new Error(`Failed to upload part ${partNumber + 1}`);
          }

          const etag = uploadResponse.headers.get("ETag");
          if (!etag) {
            throw new Error(`ETag not received for part ${partNumber + 1}`);
          }

          uploadedParts.push({ ETag: etag, PartNumber: partNumber + 1 });

          // Step 3: Update progress
          onProgress({ loaded: partNumber + 1, total: totalParts });
        }

        // Step 4: Notify backend to complete the multipart upload
        const completeResponse = await fetch(publicEndpoint, {
          body: JSON.stringify({
            callFn: "completeMultipartUpload",
            parts: uploadedParts,
            uploadId,
            uploadKey: location,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (!completeResponse.ok) {
          throw new Error("Failed to complete multipart upload");
        }

        await completeResponse.json();
      } catch (error) {
        console.error("Error uploading document:", error);
        if (onError) {
          onError(error); // Call onError if provided
        }
      }
    },
    [publicEndpoint]
  );

  const getPdfReport = React.useCallback(
    async (projectId: string, permitId: string) => {
      try {
        if (!publicConditionPageModel) {
          throw new Error("Could not fetch pdf report");
        }
        const fileGenerationId = uuid();
        setPDFDownloadProgress(0);
        setDownloadStepPDF(FileGenerationStatus.Fetching);

        let fileGenerationResult: FileGenerationLineItem | undefined =
          undefined;
        let counter = 0;
        while (counter < 300) {
          const response = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "getPdfReport",
              fileGenerationId,
              permitId,
              projectId,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          const json = await response.json();
          fileGenerationResult = decodeFileGeneration(
            json as unknown as FileGeneration
          );

          setDownloadStepPDF(fileGenerationResult.status);
          if (fileGenerationResult.status === FileGenerationStatus.Error) {
            setError("Could not fetch pdf report");
            break;
          } else if (
            fileGenerationResult.status === FileGenerationStatus.Generated
          ) {
            break;
          }
          await sleep(1000);
          counter++;
        }

        if (fileGenerationResult && fileGenerationResult.url) {
          const response = await fetch(fileGenerationResult.url);

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = getFileNameFromUrl(fileGenerationResult.url); // Set the desired file name
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      } catch (e: any) {
        console.log(e);
        setError("Could not fetch pdf report");
        setDownloadStepPDF(FileGenerationStatus.Error);
      } finally {
        setDownloadStepPDF(FileGenerationStatus.Idle);
      }
    },
    [publicConditionPageModel, publicEndpoint]
  );

  const getExcelReport = React.useCallback(
    async (projectId: string, permitId: string) => {
      try {
        if (!publicConditionPageModel) {
          throw new Error("Could not fetch excel report");
        }
        const fileGenerationId = uuid();
        setExcelDownloadProgress(0);
        setDownloadStepExcel(FileGenerationStatus.Fetching);

        let fileGenerationResult: FileGenerationLineItem | undefined =
          undefined;
        let counter = 0;
        while (counter < 300) {
          const response = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "getXlsReport",
              fileGenerationId,
              permitId,
              profileId: publicConditionPageModel?.userProfile?.id || "",
              projectId,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          const json = await response.json();
          fileGenerationResult = decodeFileGeneration(
            json as unknown as FileGeneration
          );

          setDownloadStepExcel(fileGenerationResult.status);
          if (fileGenerationResult.status === FileGenerationStatus.Error) {
            setError("Could not fetch pdf report");
            break;
          } else if (
            fileGenerationResult.status === FileGenerationStatus.Generated
          ) {
            break;
          }
          await sleep(1000);
          counter++;
        }

        if (fileGenerationResult && fileGenerationResult.url) {
          const response = await fetch(fileGenerationResult.url);

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = getFileNameFromUrl(fileGenerationResult.url); // Set the desired file name
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          setError("Could not fetch pdf report");
          setDownloadStepExcel(FileGenerationStatus.Error);
        }
      } catch (e: any) {
        console.log(e);
        setError("Could not fetch pdf report");
        setDownloadStepExcel(FileGenerationStatus.Error);
      } finally {
        setDownloadStepExcel(FileGenerationStatus.Idle);
      }
    },
    [publicConditionPageModel, publicEndpoint]
  );
  const getFullReportInfo = React.useCallback(
    async (projectId: string, permitId: string) => {
      try {
        if (!publicConditionPageModel) {
          throw new Error("Could not fetch full report");
        }
        const fileGenerationId = uuid();
        setAllFilesDownloadProgress(0);
        setDownloadStepAllFiles(FileGenerationStatus.Fetching);

        let fileGenerationResult: FileGenerationLineItem | undefined =
          undefined;
        let counter = 0;
        while (counter < 300) {
          const response = await fetch(publicEndpoint, {
            body: JSON.stringify({
              callFn: "getFileGeneration",
              documentIds: publicConditionPageModel.documents.map((d) => d.id),
              fileGenerationId,
              permitId,
              profileId: "",
              projectId,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          const json = await response.json();
          fileGenerationResult = decodeFileGeneration(json as FileGeneration);

          setDownloadStepAllFiles(fileGenerationResult.status);
          setAllFilesDownloadProgress(fileGenerationResult.progress || 0);

          if (fileGenerationResult.status === FileGenerationStatus.Error) {
            setError("Could not fetch pdf report");
            break;
          } else if (
            fileGenerationResult.status === FileGenerationStatus.Generated
          ) {
            break;
          }
          await sleep(1000);
          counter++;
        }
        setAllFilesDownloadProgress(0);

        if (fileGenerationResult && fileGenerationResult.url) {
          const response = await fetch(fileGenerationResult.url);

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          // Ensure response.body exists
          if (!response.body) {
            throw new Error(
              "ReadableStream is not supported or response body is null"
            );
          }

          const totalBytes = fileGenerationResult.fileSize || 0;

          let loadedBytes = 0;

          const reader = response.body.getReader();
          const chunks = [];

          let done = false;

          do {
            const { done: chunkDone, value } = await reader.read();
            done = chunkDone;

            if (value) {
              chunks.push(value);
              loadedBytes += value.length;

              // Call the progress callback with the percentage
              setAllFilesDownloadProgress((loadedBytes / totalBytes) * 100);
            }
          } while (!done);

          const blob = new Blob(chunks);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = getFileNameFromUrl(fileGenerationResult.url); // Set the desired file name
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          setError("Could not fetch pdf report");
          setDownloadStepAllFiles(FileGenerationStatus.Error);
        }
      } catch (e: any) {
        console.log(e);
        setError("Could not fetch pdf report");
        setDownloadStepAllFiles(FileGenerationStatus.Error);
      } finally {
        setDownloadStepAllFiles(FileGenerationStatus.Idle);
      }
    },
    [publicConditionPageModel, publicEndpoint]
  );

  React.useEffect(() => {
    if (accessId) {
      getPublicConditonModel(accessId);
    }
  }, [accessId]);

  return {
    PDFDownloadProgress,
    allFilesDownloadProgress,
    createComments,
    createDocuments,
    deleteComment,
    deleteDocument,
    downloadStepAllFiles,
    downloadStepExcel,
    downloadStepPDF,
    error,
    excelDownloadProgress,
    getExcelReport,
    getFullReportInfo,
    getPdfReport,
    getPublicConditonModel,
    isConditionLoading,
    isConditionUpdating,
    publicConditionPageModel,
    updateCondition,
    updateDocuments,
    uploadDocument,
  };
};
