import { Grid, Divider, Skeleton, Stack } from "@mui/material";
import "./styles.css";

import { HeaderTabs, HeaderTabConfig } from "./HeaderTabs";
import { BreadcrumbData, Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { DatePicker } from "../DatePicker/DatePicker";
import {
  DropDownOption,
  HeaderDropdown,
} from "../HeaderDropdown/HeaderDropDown";
import IconTextButton from "../IconTextButton/IconTextButton";
import { PageHeading } from "../PageHeading/PageHeading";
import SearchBox from "../SearchBox/SearchBox";

interface HeaderProps {
  loading: boolean;
  mainTitle: string;
  subTitle?: string;
  searchTerm?: string;
  handleDateChange?: (date: Date) => Promise<void>;
  currentDate?: Date;
  handleSearchChange?: (searchTerm: string) => Promise<void>;
  handleOptionChange?: (id?: string) => Promise<void>;
  options?: DropDownOption[];
  handleTabChange?: (tabIndex: number) => Promise<void>;
  handleBack?: () => void;
  buttons?: HeaderButtonConfig[];
  tabs?: HeaderTabConfig[];
  tabIndex?: number;
  breadcrumbs?: BreadcrumbData[];
  breadcrumbIcon?: JSX.Element;
  paddingTop?: string;
  isSubheading?: boolean;
}

export enum HeaderButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DANGER = "danger",
  SUCCESS = "success",
}

export interface HeaderButtonConfig {
  text: string;
  action: () => Promise<void>;
  icon: JSX.Element;
  type: HeaderButtonType;
  width?: number;
  minWidth?: number;
  disabled?: boolean;
}

export const Header = ({
  breadcrumbIcon,
  breadcrumbs,
  buttons = [],
  currentDate,
  handleBack,
  handleDateChange,
  handleOptionChange,
  handleSearchChange,
  handleTabChange,
  isSubheading,
  loading,
  mainTitle,
  options,
  paddingTop = "32px",
  searchTerm,
  subTitle,
  tabIndex = 0,
  tabs = [],
}: HeaderProps): JSX.Element => {
  return (
    <Grid
      item
      md={12}
      sx={{
        paddingLeft: isSubheading ? "14px" : undefined,
        paddingRight: isSubheading ? "14px" : undefined,
        paddingTop,
      }}
    >
      <Stack direction={"column"} gap={"20px"}>
        {breadcrumbs ? (
          <Breadcrumbs
            loading={loading}
            data={breadcrumbs}
            icon={breadcrumbIcon}
          />
        ) : null}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"8px"}
        >
          <PageHeading
            title={mainTitle}
            subHeading={subTitle}
            loading={loading}
            handleBack={handleBack}
          />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"12px"}
          >
            {handleSearchChange ? (
              <SearchBox
                loading={loading}
                handleSearchChange={handleSearchChange}
                searchTerm={searchTerm || ""}
              />
            ) : null}
            {handleOptionChange && options ? (
              <HeaderDropdown
                loading={loading}
                options={options}
                handleOptionChange={handleOptionChange}
              />
            ) : null}
            {handleDateChange ? (
              <DatePicker
                currentDate={currentDate || new Date()}
                handleDateChange={handleDateChange}
                loading={loading}
              />
            ) : null}
            {handleSearchChange && buttons.length ? (
              <Divider
                orientation="vertical"
                sx={{ backgroundColor: "#E9EAEB", height: "35px" }}
              />
            ) : null}
            {buttons.map((button: HeaderButtonConfig, index: number) =>
              loading ? (
                <Skeleton animation="wave" variant="rounded" key={index}>
                  <IconTextButton
                    text={button.text}
                    handleClick={button.action}
                    disabled={loading || button.disabled}
                    Icon={button.icon}
                  />
                </Skeleton>
              ) : (
                <IconTextButton
                  disabled={loading || button.disabled}
                  key={index}
                  text={button.text}
                  handleClick={button.action}
                  Icon={button.icon}
                  width={button.width}
                  minWidth={button.minWidth}
                  bgColor={
                    !loading && button.type === HeaderButtonType.PRIMARY
                      ? "#155EEF"
                      : button.type === HeaderButtonType.DANGER
                      ? "#dc3545"
                      : button.type === HeaderButtonType.SUCCESS
                      ? "#28a745"
                      : undefined
                  }
                />
              )
            )}
          </Stack>
        </Stack>
        {handleTabChange ? (
          <HeaderTabs
            loading={loading}
            tabIndex={tabIndex}
            onChange={handleTabChange}
            tabs={tabs}
          />
        ) : null}
      </Stack>
    </Grid>
  );
};
