import { useAuthContext } from "@hooks/context/useAuthContext";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "@stories/ui-component/cards/MainCard";
import Transitions from "@stories/ui-component/extended/Transitions";
import {
  IconLogout,
  IconSettings,
  IconFolder,
  IconCrossFilled,
} from "@tabler/icons-react";
import * as RouteHelper from "@utils/routes";
import { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isAuthLoading, isPermitOneAdmin, signOut, userProfile } =
    useAuthContext();
  const [open, setOpen] = useState(false);

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    try {
      await signOut();
    } catch (err) {
      console.error(err);
    }
  };
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route = ""
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route) {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        className="profileChip"
        sx={{
          "& .MuiChip-label": {
            lineHeight: 0,
          },
          ":hover": {
            backgroundColor: "#083d81",
            opacity: 0.8,
          },
          alignItems: "center",
          backgroundColor: "#083d81",
          borderRadius: "4px",
          bottom: "0",
          height: "48px",
          justifyContent: "flex-start",
          padding: "8px 12px",
          position: "fixed",
          transition: "all .2s ease-in-out",
          width: "230px",
        }}
        icon={
          !isAuthLoading && userProfile ? (
            <Avatar
              src={userProfile?.photoUrl}
              alt="user-images"
              sx={{
                height: 32,
                width: 32,
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
            />
          ) : (
            <Skeleton animation={"wave"} variant="circular">
              <Avatar
                sx={{
                  backgroundColor: "#ffffff",
                }}
              />
            </Skeleton>
          )
        }
        label={
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              fontSize: "14px !important",
              fontWeight: "500",
            }}
          >
            {userProfile?.name}
          </Typography>
        }
        variant="filled"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        aria-label="user-account"
      />

      <Popper
        className="popup"
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <PerfectScrollbar>
                      <Box sx={{ p: 1.5, pt: 1 }}>
                        <List
                          component="nav"
                          sx={{
                            // "& .MuiListItemButton-root": {
                            //   mt: 0.5,
                            // },
                            // display: "flex",
                            // justifyContent: "center",
                            maxWidth: 350,
                            minWidth: 300,
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            width: "100%",
                          }}
                        >
                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 0}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>
                            ) =>
                              handleListItemClick(
                                event,
                                0,
                                RouteHelper.listProjects
                              )
                            }
                          >
                            <ListItemIcon>
                              <IconFolder stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Projects
                                </Typography>
                              }
                            />
                          </ListItemButton>

                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 1}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>
                            ) =>
                              handleListItemClick(
                                event,
                                1,
                                RouteHelper.userAccount
                              )
                            }
                          >
                            <ListItemIcon>
                              <IconSettings stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  Account Settings
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          {isPermitOneAdmin ? (
                            <ListItemButton
                              sx={{ borderRadius: "20px", padding: "6px 12px" }}
                              selected={selectedIndex === 2}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  2,
                                  RouteHelper.godMode
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconCrossFilled
                                  stroke={1.5}
                                  color="gold"
                                  size="20px"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="body2"
                                    data-test-id="help-center-button"
                                  >
                                    GOD MODE
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          ) : null}
                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 3}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout
                                stroke={1.5}
                                color="red"
                                size="20px"
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: "red" }}
                                >
                                  Logout
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
