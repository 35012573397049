import { usePublicConditions } from "@hooks/crud/usePublicConditions";
import { Grid, styled } from "@mui/material";
import PublicConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/PublicConditionDocumentModal";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { PublicPermitCollaborators } from "@stories/organisms/PermitCollaborators/PermitCollaborators";
import { PublicPermitEventLog } from "@stories/organisms/PermitEventLog/PublicPermitEventLog";
import { PermitProperties } from "@stories/organisms/PermitProperties/PermitProperties";
import { PublicConditionSummary } from "@stories/organisms/Summary/PublicConditionSummary";
import { FeeTable } from "@stories/organisms/Tables/FeeTable/FeeTable";
import { InspectionTable } from "@stories/organisms/Tables/InspectionTable/InspectionTable";
import { LodgementTable } from "@stories/organisms/Tables/LodgementTable/LodgementTable";
import { PublicConditionTable2 } from "@stories/organisms/Tables/PublicConditionTable/PublicConditionTable2";
import {
  ConditionLineItem,
  ShareConditionAccessLevel,
} from "permit-one-common/src/interfaces/condition";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

const WhiteBox = styled("div")({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
});

export const PublicConditions = (): JSX.Element => {
  const { accessId } = useParams();
  const [selectedCondition, setSelectedCondition] = useState<
    ConditionLineItem | undefined
  >(undefined);
  const [openBulk, setOpenDocumentModal] = useState(false);

  const {
    PDFDownloadProgress,
    allFilesDownloadProgress,
    createComments,
    createDocuments,
    deleteComment,
    deleteDocument,
    downloadStepAllFiles,
    downloadStepExcel,
    downloadStepPDF,
    excelDownloadProgress,
    getExcelReport,
    getFullReportInfo,
    getPdfReport,
    isConditionLoading,
    publicConditionPageModel,
    updateCondition,
    updateDocuments,
    uploadDocument,
  } = usePublicConditions(accessId);

  const handleCloseConditionDocumentModal = useCallback(() => {
    setOpenDocumentModal(false);
    setSelectedCondition(undefined);
  }, []);

  const handleUpdateCondition = useCallback((condition: ConditionLineItem) => {
    updateCondition(condition);
  }, []);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = useCallback(async (tabIndex: number) => {
    setTabIndex(tabIndex);
  }, []);

  const loading = isConditionLoading;

  const canEdit =
    publicConditionPageModel?.shareCondition.accessLevel ===
    ShareConditionAccessLevel.Write;

  const handleOpenConditionDocumentModal = useCallback(
    async (condition: ConditionLineItem) => {
      setSelectedCondition(condition);
      setOpenDocumentModal(true);
    },
    []
  );

  const selectedConditionStep = (() => {
    switch (tabIndex) {
      default:
      case 0:
        return (
          <PublicConditionTable2
            loading={loading}
            canEdit={canEdit}
            disabled={false}
            shareCondition={publicConditionPageModel?.shareCondition}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
            conditions={publicConditionPageModel?.conditions || []}
            handleUpdateCondition={handleUpdateCondition}
            handleOpenConditionDocumentModal={handleOpenConditionDocumentModal}
            getExcelReport={getExcelReport}
            getPdfReport={getPdfReport}
            getFullReportInfo={getFullReportInfo}
            downloadStepExcel={downloadStepExcel}
            downloadStepPDF={downloadStepPDF}
            downloadStepAllFiles={downloadStepAllFiles}
            excelDownloadProgress={excelDownloadProgress}
            PDFDownloadProgress={PDFDownloadProgress}
            allFilesDownloadProgress={allFilesDownloadProgress}
          />
        );
      case 1:
        return (
          <PermitProperties
            loading={loading}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
            readOnly
            permits={
              publicConditionPageModel?.permit
                ? [publicConditionPageModel?.permit]
                : []
            }
          />
        );
      // case 2:
      //   return (
      //     <MailCenter
      //       isLoading={loading}
      //       project={publicConditionPageModel?.project}
      //       permit={publicConditionPageModel?.permit}
      //       profile={publicConditionPageModel?.userProfile}
      //       showDialog={false}
      //     />
      //   );
      case 2:
        return (
          <PublicPermitCollaborators
            loading={loading}
            directory={publicConditionPageModel?.directory || []}
          />
        );
      case 3:
        return (
          <PublicPermitEventLog
            loading={loading}
            permit={publicConditionPageModel?.permit}
            permitEvents={publicConditionPageModel?.activity || []}
          />
        );
      case 5:
        return (
          <InspectionTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 6:
        return (
          <FeeTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 7:
        return (
          <LodgementTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
    }
  })();
  {
    publicConditionPageModel?.project?.projectName;
  }

  const tabs = [
    {
      label: "Checklist",
    },
    {
      label: "Permit Details",
    },
    {
      label: "Contacts",
    },
    {
      label: "Event Log",
    },
  ];
  return (
    <PageContainer>
      <Header
        subTitle={
          publicConditionPageModel?.permit?.permitName || "PermitOne Permits"
        }
        mainTitle={
          publicConditionPageModel?.project?.projectName || "PermintOne Project"
        }
        loading={loading}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      <Grid item md={12}>
        <PublicConditionSummary
          conditionCounts={publicConditionPageModel?.conditionCounts}
          shareConditionId={publicConditionPageModel?.shareCondition.id}
          loading={loading}
        />
      </Grid>

      <Grid item md={12}>
        <WhiteBox>{selectedConditionStep}</WhiteBox>
      </Grid>
      {publicConditionPageModel?.userProfile &&
      publicConditionPageModel?.project &&
      publicConditionPageModel?.permit &&
      selectedCondition &&
      openBulk ? (
        <PublicConditionDocumentModal
          open={openBulk}
          canEdit={canEdit}
          shareCondition={publicConditionPageModel?.shareCondition}
          project={publicConditionPageModel?.project}
          documents={publicConditionPageModel?.documents}
          comments={publicConditionPageModel?.comments}
          handleClose={handleCloseConditionDocumentModal}
          permit={publicConditionPageModel?.permit}
          condition={selectedCondition}
          handleUpdateCondition={updateCondition}
          createComments={createComments}
          createDocuments={createDocuments}
          deleteComment={deleteComment}
          deleteDocument={deleteDocument}
          updateDocuments={updateDocuments}
          uploadFile={uploadDocument}
          loading={loading}
          disabled={loading}
        />
      ) : null}
    </PageContainer>
  );
};
