import EditIcon from "@mui/icons-material/Edit";
import { Chip, Grid } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { TextRow } from "@stories/atoms/TextRow/TextRow";
import parse from "html-react-parser";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import { permitTypeToString } from "permit-one-common/src/entities/permit";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { getProfileName } from "permit-one-common/src/interfaces/profile";
import {
  ProjectLineItem,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import ConditionModal from "../ConditionModal/ConditionModal";

interface ConditionsPropertiesProps {
  loading: boolean;
  canEdit?: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  handleUpdateCondition: (condition: ConditionLineItem) => void;
}

export const ConditionProperties = ({
  canEdit,
  condition,
  handleUpdateCondition,
  loading,
  permit,
  project,
}: ConditionsPropertiesProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex !important",
          justifyContent: "right !important;",
        }}
      >
        {canEdit ? (
          <StyledButton
            loading={loading}
            variant="contained"
            startIcon={<EditIcon />}
            onClick={handleOpen}
          >
            Edit
          </StyledButton>
        ) : undefined}
      </Grid>
      <Grid className="permitDetails" sx={{ padding: "0" }}>
        <TextRow
          loading={loading}
          title={"Permit Item #"}
          text={condition?.permitItemNumber || ""}
        />
        <TextRow
          loading={loading}
          title={"Name of Condition or Requirement"}
          text={condition?.conditionName || ""}
        />
        <TextRow
          loading={loading}
          title={"Action Required"}
          text={
            condition?.conditionDetails ? parse(condition.conditionDetails) : ""
          }
        />
        <TextRow
          loading={loading}
          title={"Project Name"}
          text={project?.projectName || ""}
        />
        <TextRow
          loading={loading}
          title={"Project Type"}
          text={
            project
              ? `${projectSectorToString(project.projectSector)} ${
                  project.projectSectorOther
                }`
              : ""
          }
        />
        <TextRow
          loading={loading}
          title={"Permit Name"}
          text={permit?.permitName || ""}
        />
        <TextRow
          loading={loading}
          title={"Permit Type"}
          text={permit ? permitTypeToString(permit.permitType) : ""}
        />
        {/* <TextRow
          loading={loading}
          title={"Value of Works"}
          text={permit ? `$${permit.valueOfPermitWork}` : ""}
        /> */}
        <TextRow
          loading={loading}
          title={"Comments"}
          text={
            condition?.conditionComments
              ? parse(condition.conditionComments)
              : ""
          }
        />
        <TextRow
          loading={loading}
          title={"Permit Manager"}
          text={
            permit && permit.managers.length > 0
              ? permit.managers.map((a) => getProfileName(a)).join(", ")
              : conditionStatusToString(ConditionStatus.NotAssigned, false)
          }
        />
        <TextRow
          loading={loading}
          title={"Permit Approver"}
          text={
            permit && permit.approvers.length > 0
              ? permit.approvers.map((a) => getProfileName(a)).join(", ")
              : conditionStatusToString(ConditionStatus.NotAssigned, false)
          }
        />
        <TextRow
          loading={loading}
          title={"Responsible Assignee"}
          text={
            condition && condition.assignees.length > 0
              ? condition.assignees.map((a) => getProfileName(a)).join(", ")
              : conditionStatusToString(ConditionStatus.NotAssigned, false)
          }
        />
        <TextRow
          loading={loading}
          title={"Discipline"}
          text={condition?.discipline}
        />
        <TextRow
          loading={loading}
          title={"Due Date"}
          text={condition ? condition?.dueDate.toLocaleDateString() : ""}
        />
        <TextRow loading={loading} title={"Status"} text={""}>
          <Chip
            label={
              condition
                ? conditionStatusToString(
                    condition.status,
                    Boolean(condition.markedAsNotApplicable)
                  )
                : ""
            }
            sx={{
              backgroundColor: condition
                ? conditionStatusToColor(
                    condition.status,
                    Boolean(condition.markedAsNotApplicable)
                  )
                : "",
              color: "black",
            }}
          />
        </TextRow>
      </Grid>
      {project && permit && open ? (
        <ConditionModal
          open={open}
          project={project}
          permit={permit}
          conditionCount={1}
          handleClose={handleClose}
          createCondition={() => {
            console.log("Not implemented");
          }}
          permitItemNumbers={new Map<ConditionAuthority, number[]>()}
          updateCondition={handleUpdateCondition}
          existingCondition={condition}
        />
      ) : null}
    </>
  );
};
