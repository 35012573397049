import { Divider, MenuItem, Stack, styled, Typography } from "@mui/material";
import { CellAction } from "@stories/organisms/Table/TableCells";

interface MenuItemProps {
  index: number;
  arr: CellAction[];
  data: any;
  action: CellAction;
  setAnchorEl: (value: React.SetStateAction<HTMLElement | null>) => void;
  useAlt: boolean;
}

const StyledTypography = styled(Typography)({
  color: "black",
  fontFamily: "'Inter'",
  fontSize: "12px",
  lineHeight: "18px",
});

const CustomMenuItem = ({
  action,
  arr,
  data,
  index,
  setAnchorEl,
  useAlt,
}: MenuItemProps) => {
  return (
    <>
      <MenuItem
        disableRipple
        onClick={async () => {
          if (useAlt && action.actionAlt) {
            await action.actionAlt(data);
          } else {
            await action.action(data);
          }
          setAnchorEl(null);
        }}
        sx={{ p: 0 }}
        disabled={action.disabled}
      >
        <Stack alignItems={"center"} flexDirection={"row"} gap={1}>
          {useAlt && action.iconAlt ? action.iconAlt : action.icon}
          <StyledTypography>
            {useAlt && action.titleAlt ? action.titleAlt : action.title}
          </StyledTypography>
        </Stack>
      </MenuItem>
      {arr.length > 1 && index < arr.length - 1 && (
        <Divider
          key={`${index}-divider`}
          orientation="horizontal"
          sx={{ backgroundColor: "#D5D7DA" }}
        />
      )}
    </>
  );
};

export default CustomMenuItem;
