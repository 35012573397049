import { useProjectContext } from "@hooks/context/useProjectContext";
import { useTask } from "@hooks/crud/useTask";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { TaskSummary } from "@stories/organisms/Summary/TaskSummary";
import { TaskTable2 } from "@stories/organisms/Tables/TaskTable/TaskTable2";
import * as RouteHelper from "@utils/routes";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const Tasks = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { projectId } = useParams();
  const { selectedProject } = useProjectContext();
  const { isTaskLoading, tasks } = useTask(projectId);

  const handleViewCondition = (task: ConditionLineItem) => {
    const params = new URLSearchParams({
      id: task.id,
    });

    navigate(
      `${RouteHelper.conditions(
        task.projectId,
        task.permitId
      )}?${params.toString()}`
    );
  };

  const params = new URLSearchParams(location.search);
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(params.get("status") || "0")
  );

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const loading = isTaskLoading;

  const tabs = [
    {
      label: "All",
    },
    {
      label: "Assigned",
    },
    {
      label: "In Review",
    },
    {
      label: "Approved",
    },
    {
      label: "Not Assigned",
    },
  ];

  const filteredTasks = (() => {
    switch (tabIndex) {
      case 1:
        return tasks.filter(
          (task) =>
            task.status === ConditionStatus.Assigned &&
            !task.markedAsNotApplicable
        );
      case 2:
        return tasks.filter(
          (task) =>
            task.status === ConditionStatus.InReview &&
            !task.markedAsNotApplicable
        );
      case 3:
        return tasks.filter(
          (task) =>
            task.status === ConditionStatus.Approved &&
            !task.markedAsNotApplicable
        );
      case 4:
        return tasks.filter((task) => task.markedAsNotApplicable);
      default:
        return tasks;
    }
  })();

  useEffect(() => {
    if (searchParams.has("status")) {
      const status = searchParams.get("status");
      setTabIndex(parseInt(status || "0"));
      setSearchParams();
    }
  }, [params]);

  return (
    <PageContainer>
      <Header
        subTitle={
          `All permit conditons for ${selectedProject?.projectName} to action` ||
          "PermitOne Documents"
        }
        mainTitle={"My Tasks"}
        loading={loading}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />
      <TaskSummary
        taskCounts={{
          approved: tasks.filter(
            (task) =>
              task.status === ConditionStatus.Approved &&
              !task.markedAsNotApplicable
          ).length,
          assigned: tasks.filter(
            (task) =>
              task.status === ConditionStatus.Assigned &&
              !task.markedAsNotApplicable
          ).length,
          inReview: tasks.filter(
            (task) =>
              task.status === ConditionStatus.InReview &&
              !task.markedAsNotApplicable
          ).length,
          notApplicable: tasks.filter((task) => task.markedAsNotApplicable)
            .length,
          notAssigned: tasks.filter(
            (task) =>
              task.status === ConditionStatus.NotAssigned &&
              !task.markedAsNotApplicable
          ).length,
          overdue: tasks.filter(
            (task) =>
              task.status === ConditionStatus.Overdue &&
              !task.markedAsNotApplicable
          ).length,
        }}
        project={selectedProject}
        loading={loading}
      />
      <TaskTable2
        loading={loading}
        searchTerm={searchTerm}
        tasks={filteredTasks}
        handleViewtask={handleViewCondition}
      />
    </PageContainer>
  );
};
