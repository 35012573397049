import { Box, Chip, Grid } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { DocumentViewerModal } from "@stories/molecules/DocumentViewerModal/DocumentViewerModal";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CheckboxCell,
  HeaderCheckboxCell,
  CellAction,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";
import {
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import {
  ConditionDocumentLineItem,
  documentCategoryToString,
} from "permit-one-common/src/interfaces/conditionDocument";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useCallback, useState } from "react";

interface DocumentTableProps {
  loading: boolean;
  documents: ConditionDocumentLineItem[];
  selectedDocuments: ConditionDocumentLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  searchTerm: string;
  permits: PermitLineItem[];
  handleOpenBulk: () => void;
  handleEditDocument: (document: ConditionDocumentLineItem) => void;
  handleDeleteDocument: (document: ConditionDocumentLineItem) => void;
  handleSetSelectedDocuments: (documents: ConditionDocumentLineItem[]) => void;
}

export const DocumentTable2 = ({
  documents,
  handleSetSelectedDocuments,
  loading,
  organisation,
  profile,
  searchTerm,
  selectedDocuments,
}: DocumentTableProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [documentKey, setDocumentKey] = useState<string>();

  const handleView = useCallback(
    async (conditinDocument: ConditionDocumentLineItem) => {
      const uri = conditinDocument?.url;
      if (!uri) return;
      setDocumentKey(uri);
    },
    []
  );
  const handleSelectAll = useCallback(() => {
    if (!selectedDocuments.length) {
      handleSetSelectedDocuments([...documents]);
    } else {
      handleSetSelectedDocuments([]);
    }
  }, [documents, selectedDocuments]);

  const handleCheck = useCallback(
    (conditionDocument: ConditionDocumentLineItem) => {
      if (selectedDocuments.map((d) => d.id).includes(conditionDocument.id)) {
        handleSetSelectedDocuments(
          selectedDocuments.filter((doc) => doc.id !== conditionDocument.id)
        );
      } else {
        handleSetSelectedDocuments([...selectedDocuments, conditionDocument]);
      }
    },
    [selectedDocuments, documents]
  );

  const [colDefs] = useState<ColDef[]>([
    {
      cellClass: "center-ag-cell",
      cellRenderer: CheckboxCell,
      cellStyle: centerCellStyles,
      field: "checkbox",
      headerClass: "centered-table-header",
      headerComponent: HeaderCheckboxCell, // Use custom header
      headerName: "",
    },
    {
      cellClass: "center-ag-cell",
      field: "documentTitle",
      flex: 2,
      headerName: "Title",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
      valueFormatter: (params) => params.data.documentTitle,
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: centerCellStyles,
      field: "documentDiscipline",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Discipline",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
      valueFormatter: (params) =>
        conditionDisciplineToString(params.data.documentDiscipline),
    },
    {
      field: "documentCategory",
      flex: 1.5,
      headerName: "Category",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
      valueFormatter: (params) =>
        documentCategoryToString(params.data.documentCategory),
    },
    {
      field: "createdByName",
      headerName: "Creator",
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: centerCellStyles,
      field: "modified",
      flex: 1.5,
      headerClass: "centered-table-header",
      headerName: "Uploaded",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
      valueFormatter: (params) => params.data.modified.toLocaleDateString(),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: (params: any) => {
        return (
          <Box display="flex" justifyContent="center">
            <Chip
              size="small"
              label={conditionStatusToString(
                params.data.condition.status,
                Boolean(params.data.condition.markedAsNotApplicable)
              )}
              variant="filled"
              background-color={conditionStatusToColor(
                params.data.condition.status,
                Boolean(params.data.condition.markedAsNotApplicable)
              )}
              sx={{
                backgroundColor: conditionStatusToColor(
                  params.data.condition.status,
                  Boolean(params.data.condition.markedAsNotApplicable)
                ),
                margin: "auto",
              }}
            />
          </Box>
        );
      },
      cellStyle: centerCellStyles,
      field: "activePermits",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "Status",
      onCellClicked: (params) => {
        return handleView(params.data);
      },
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[]);

  const cellActions: CellAction[] = [
    {
      action: handleView,
      icon: <ViewProjectIcon />,
      title: "View Document",
    },
  ];

  const handleOpenConfirmDialog = (_: ProjectLineItem) => {
    //setSelectedProject(project);
    setOpenConfirmDialog(true);
  };

  return (
    <Grid item xs={12} md={12} mt={2}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={documents}
        searchValue={searchTerm}
        context={{
          allAreSelected: selectedDocuments.length === documents.length,
          cellActions,
          handleCheck,
          handleOpenConfirmDialog,
          handleSelectAll,
          organisation,
          profile,
          selectedIds: selectedDocuments.map((doc) => doc.id),
        }}
      />
      <ConfirmationDialog
        message={"Are you sure you want to delete this docuemnt?"}
        open={openConfirmDialog}
        title={"Delete Document"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => undefined}
      />
      {documentKey ? (
        <DocumentViewerModal
          documentKey={documentKey}
          setDocumentKey={setDocumentKey}
        />
      ) : undefined}
    </Grid>
  );
};
