import { Grid } from "@mui/material";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  ActionCell,
  CellAction,
  AvatarNameCellRenderer,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ArchiveProjectIcon } from "assets/constants";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";

interface CollaboratorTable2Props {
  loading: boolean;
  searchTerm: string;
  directory: DirectoryContactLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  handleToggleShareCondition?: (
    contact: DirectoryContactLineItem
  ) => Promise<void>;
  readOnly?: boolean;
}

export const CollaboratorTable2 = ({
  directory,
  handleToggleShareCondition,
  loading,
  organisation,
  profile,
  readOnly,
  searchTerm,
}: CollaboratorTable2Props) => {
  // const [selectedProject, setSelectedProject] =
  //   useState<ProjectLineItem | null>(null);

  const cellActions = [
    {
      action: handleToggleShareCondition,
      icon: <ArchiveProjectIcon />,
      title: "Remove Access",
      validateEnabled: (data: DirectoryContactLineItem) => {
        return profile?.id !== data.id;
      },
    },
  ] as CellAction[];
  const colDefs = [
    {
      cellRenderer: AvatarNameCellRenderer,
      cellStyle: centerCellStyles,
      field: "name",
      flex: 1.5,
      headerClass: "left-table-header",
      headerName: "Name",
    },
    {
      cellStyle: centerCellStyles,
      field: "organisationName",
      headerClass: "left-table-header",
      headerName: "Company",
    },
    {
      cellStyle: centerCellStyles,
      field: "jobTitle",
      headerClass: "left-table-header",
      headerName: "Role",
    },
    {
      cellStyle: centerCellStyles,
      field: "email",
      headerClass: "left-table-header",
      headerName: "Email",
    },
    {
      cellStyle: centerCellStyles,
      field: "phoneNumber",
      headerClass: "left-table-header",
      headerName: "Phone",
    },
  ] as (ColDef | ColGroupDef)[];

  if (!readOnly) {
    colDefs.push({
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "",
    });
  }

  return (
    <Grid item xs={12} md={12}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={directory}
        searchValue={searchTerm}
        noFullTableBorder
        context={{
          cellActions,
          organisation,
          profile,
        }}
        searchKeys={[
          "name",
          "organisationName",
          "jobTitle",
          "email",
          "phoneNumber",
        ]}
      />
    </Grid>
  );
};
