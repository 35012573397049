import { useAuthContext } from "@hooks/context/useAuthContext";
import { usePermitOneAdmin } from "@hooks/crud/usePermitOneAdmin";
import { GodModeTable } from "@stories/organisms/GodModeTable/GodModeTable";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { useState } from "react";

export const GodMode = (): JSX.Element => {
  const { userProfile } = useAuthContext();
  const { isPermitOneAdminLoading, joinOrganisation, organisations } =
    usePermitOneAdmin();

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleJoinOrganisation = async (org: OrganisationLineItem) => {
    joinOrganisation(org.id);
  };

  const loading = isPermitOneAdminLoading;

  return (
    <PageContainer>
      <Header
        subTitle={"Allfather’s Throne: Shape Fate, Wield Absolute Power"}
        mainTitle={"God Mode"}
        loading={loading}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />
      <GodModeTable
        loading={loading}
        organisations={organisations}
        handleJoinOrganisation={handleJoinOrganisation}
        profile={userProfile}
        searchTerm={searchTerm}
      />
    </PageContainer>
  );
};
