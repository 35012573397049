import { getApiData } from "@hooks/utils/api";
import { downloadFile } from "@hooks/utils/useUpload";
import { decodeFileGeneration } from "permit-one-common/src/decoders/fileGeneration";
import {
  FileGeneration,
  FileGenerationStatus,
} from "permit-one-common/src/entities/fileGeneration";
import { FileGenerationLineItem } from "permit-one-common/src/interfaces/fileGeneration";
import { sleep } from "permit-one-common/src/utils/sleep";
import { getFileNameFromUrl } from "permit-one-common/src/utils/string";
import * as React from "react";
import { v4 as uuid } from "uuid";

export const useDownloadDocuments = () => {
  const [downloadStep, setDownloadStep] = React.useState<FileGenerationStatus>(
    FileGenerationStatus.Idle
  );
  const [downloadProgress, setDownloadProgress] = React.useState<number>(0);

  const [error, setError] = React.useState<string | null>(null);
  const getDocumentsZip = async (projectId: string, documentIds: string[]) => {
    try {
      const fileGenerationId = uuid();
      setDownloadProgress(0);
      setDownloadStep(FileGenerationStatus.Fetching);

      let fileGenerationResult: FileGenerationLineItem | undefined = undefined;
      let counter = 0;
      while (counter < 300) {
        const profileResult = await getApiData(
          "getDocuments",
          "fileGeneration",
          fileGenerationId,
          {
            documentIds: JSON.stringify(documentIds),
            projectId,
          }
        );
        fileGenerationResult = decodeFileGeneration(
          profileResult.data as FileGeneration
        );

        setDownloadStep(fileGenerationResult.status);
        setDownloadProgress(fileGenerationResult.progress || 0);

        if (fileGenerationResult.status === FileGenerationStatus.Error) {
          setError("Could not fetch  report");
          break;
        } else if (
          fileGenerationResult.status === FileGenerationStatus.Generated
        ) {
          break;
        }
        await sleep(1000);
        counter++;
      }

      setDownloadProgress(0);
      if (fileGenerationResult && fileGenerationResult.url) {
        const zipFileTitle = getFileNameFromUrl(fileGenerationResult.url);
        await downloadFile(
          fileGenerationResult.url,
          zipFileTitle,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;

            setDownloadProgress(total);
            if (total >= 100) {
              setDownloadStep(FileGenerationStatus.Idle);
            }
          }
        );
      } else {
        setError("Could not fetch  report");
        setDownloadStep(FileGenerationStatus.Error);
      }
    } catch (e) {
      console.log(e);
      setError("Could not fetch  report");
      setDownloadStep(FileGenerationStatus.Error);
    } finally {
      setDownloadStep(FileGenerationStatus.Idle);
    }
  };

  return {
    downloadProgress,
    downloadStep,
    error,
    getDocumentsZip,
  };
};
