import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { Country, State } from "country-state-city";
import { useFormik } from "formik";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import * as yup from "yup";

interface ProjectAddressFormProps {
  project: ProjectLineItem;
  handleBack: () => void;
  handleSubmit: (profile: ProjectLineItem) => void;
}

export const ProjectAddressForm = ({
  handleBack,
  handleSubmit,
  project,
}: ProjectAddressFormProps) => {
  const validationSchema = yup.object({
    country: yup.string().required("Country is required"),
    dpsMpsNumber: yup.string().required("Dps Mps Number is required"),
    lotNumber: yup.string().required("Lot number is required"),
    managers: yup.array(),
    postCode: yup
      .string()
      .required("Post code is required")
      .test(
        "Valid for country",
        "Not a valid post code for country",
        function (item) {
          if (postcodeValidatorExistsForCountry(this.parent.country)) {
            const valid = postcodeValidator(item, this.parent.country);
            return valid;
          }
          return true;
        }
      ),
    state: yup.string().required("State is required"),
    streetName: yup.string().required("Street name is required"),
    streetNumber: yup.string().required("Street number is required"),
    suburb: yup.string().required("Suburb is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      country: project.country,
      dpsMpsNumber: project.dpsMpsNumber,
      lotNumber: project.lotNumber,
      postCode: project.postCode,
      state: project.state,
      streetName: project.streetName,
      streetNumber: project.streetNumber,
      suburb: project.suburb,
    },
    onSubmit: async (values) => {
      await handleSubmit({
        ...project,
        country: values.country,
        dpsMpsNumber: values.dpsMpsNumber,
        lotNumber: values.lotNumber,
        postCode: values.postCode,
        state: values.state,
        streetName: values.streetName,
        streetNumber: values.streetNumber,
        suburb: values.suburb,
      });
    },
    validationSchema: validationSchema,
  });

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            id="streetNumber"
            name="streetNumber"
            label="Street Number"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.streetNumber}
            error={
              formik.touched.streetNumber && Boolean(formik.errors.streetNumber)
            }
            helperText={
              formik.touched.streetNumber ? formik.errors.streetNumber : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="streetName"
            name="streetName"
            label="Street Name"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.streetName}
            error={
              formik.touched.streetName && Boolean(formik.errors.streetName)
            }
            helperText={
              formik.touched.streetName ? formik.errors.streetName : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            select
            id="country"
            name="country"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country ? formik.errors.country : ""}
            label="Country"
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: "200px !important",
                    overflowY: "auto",
                  },
                },
              },
            }}
          >
            {countries.map((c) => (
              <MenuItem key={c.isoCode} value={c.isoCode}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <TextField
            select
            id="state"
            name="state"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state ? formik.errors.state : ""}
            label="State"
          >
            {states.map((s, index) => (
              <MenuItem key={`${s.isoCode}-${index}`} value={s.isoCode}>
                {s.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <TextField
            id="suburb"
            name="suburb"
            label="Suburb"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.suburb}
            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            helperText={formik.touched.suburb ? formik.errors.suburb : ""}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="postCode"
            name="postCode"
            label="Post Code"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postCode}
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            helperText={formik.touched.postCode ? formik.errors.postCode : ""}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="lotNumber"
            name="lotNumber"
            label="Lot Number"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lotNumber}
            error={formik.touched.lotNumber && Boolean(formik.errors.lotNumber)}
            helperText={formik.touched.lotNumber ? formik.errors.lotNumber : ""}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="dpsMpsNumber"
            name="dpsMpsNumber"
            label="DP/Plan Number"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dpsMpsNumber}
            error={
              formik.touched.dpsMpsNumber && Boolean(formik.errors.dpsMpsNumber)
            }
            helperText={
              formik.touched.dpsMpsNumber ? formik.errors.dpsMpsNumber : ""
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          justifyContent: "space-between !important;",
          padding: "10px 0 0",
        }}
      >
        <StyledButton
          loading={false}
          onClick={handleBack}
          btnType={ButtonType.Secondary}
        >
          Back
        </StyledButton>
        <StyledButton loading={false} type="submit">
          Submit
        </StyledButton>
      </Grid>
    </form>
  );
};
