import { Checkbox, Stack, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  ButtonType,
  StyledButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { Country, State } from "country-state-city";
import { useFormik } from "formik";
import { OwnerApplicant } from "permit-one-common/src/entities/permit";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { validateABN } from "permit-one-common/src/utils/abn";
import phone from "phone";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { useState } from "react";
import * as yup from "yup";

interface PermitOwnerFormProps {
  permit: PermitLineItem;
  handleBack: () => void;
  handleSubmit: (permit: PermitLineItem) => void;
}

export const PermitOwnerForm = ({
  handleBack,
  handleSubmit,
  permit,
}: PermitOwnerFormProps) => {
  const [formEnabled, setFormEnabled] = useState(
    permit.ownerApplicant == OwnerApplicant.Owner
  );

  const validationSchema = yup.object({
    abn: formEnabled
      ? yup
          .string()
          .required("ABN is required")
          .test(
            "Valid ABN",
            "Not a valid ABN - i.e. 53 004 085 616",
            function (item) {
              return validateABN(item);
            }
          )
      : yup.string(),
    contactPersonEmail: formEnabled
      ? yup
          .string()
          .required("Contact person email is required")
          .email("Email is not valid")
      : yup.string(),
    contactPersonName: formEnabled
      ? yup.string().required("Contact person name is required")
      : yup.string(),
    contactPersonPhone: formEnabled
      ? yup
          .string()
          .required("Contact person phone is required")
          .test("Valid Phone", "Phone not valid for country", function (item) {
            const res = phone(item, {
              country: this.parent.country,
              validateMobilePrefix: false,
            });
            return res.isValid;
          })
      : yup.string(),
    country: formEnabled
      ? yup.string().required("Country is required")
      : yup.string(),
    ownerName: formEnabled
      ? yup.string().required("Owner name is required")
      : yup.string(),
    postCode: formEnabled
      ? yup
          .string()
          .required("Post code is required")
          .test(
            "Valid for country",
            "Not a valid post code for country",
            function (item) {
              if (postcodeValidatorExistsForCountry(this.parent.country)) {
                const valid = postcodeValidator(item, this.parent.country);
                return valid;
              }
              return true;
            }
          )
      : yup.string(),
    state: formEnabled
      ? yup.string().required("State is required")
      : yup.string(),
    streetName: formEnabled
      ? yup.string().required("Street name is required")
      : yup.string(),
    streetNumber: formEnabled
      ? yup.string().required("Street number is required")
      : yup.string(),
    suburb: formEnabled
      ? yup.string().required("Suburb is required")
      : yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      abn: permit.ownerAbn,
      contactPersonEmail: permit.ownerContactPersonEmail,
      contactPersonName: permit.ownerContactPersonName,
      contactPersonPhone: permit.ownerContactPersonPhone,
      country: permit.ownerCountry,
      ownerName: permit.ownerApplicantName,
      postCode: permit.ownerPostCode,
      state: permit.ownerState,
      streetName: permit.ownerStreetName,
      streetNumber: permit.ownerStreetNumber,
      suburb: permit.ownerSuburb,
    },
    onSubmit: async (values) => {
      await handleSubmit({
        ...permit,
        ownerAbn: values.abn,
        ownerApplicant: formEnabled
          ? OwnerApplicant.Owner
          : OwnerApplicant.Applicant,
        ownerApplicantName: values.ownerName,
        ownerContactPersonEmail: values.contactPersonEmail,
        ownerContactPersonName: values.contactPersonName,
        ownerContactPersonPhone: values.contactPersonPhone,
        ownerCountry: values.country,
        ownerPostCode: values.postCode,
        ownerState: values.state,
        ownerStreetName: values.streetName,
        ownerStreetNumber: values.streetNumber,
        ownerSuburb: values.suburb,
      });
    },
    validationSchema: validationSchema,
  });

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);

  const label = { inputProps: { "aria-label": "Enable Form" } };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item md={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{"Is the owner the same as the applicant?"}</Typography>
            <Checkbox
              sx={{ width: "5%" }}
              {...label}
              checked={formEnabled}
              onChange={() => {
                return setFormEnabled(() => !formEnabled);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="ownerName"
            name="ownerName"
            label="Owner Name"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.ownerName}
            error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
            helperText={formik.touched.ownerName ? formik.errors.ownerName : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="abn"
            name="abn"
            label="ABN/ACN"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.abn}
            error={formik.touched.abn && Boolean(formik.errors.abn)}
            helperText={formik.touched.abn ? formik.errors.abn : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="streetNumber"
            name="streetNumber"
            label="Street Number"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.streetNumber}
            error={
              formik.touched.streetNumber && Boolean(formik.errors.streetNumber)
            }
            helperText={
              formik.touched.streetNumber ? formik.errors.streetNumber : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="streetName"
            name="streetName"
            label="Street Name"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.streetName}
            error={
              formik.touched.streetName && Boolean(formik.errors.streetName)
            }
            helperText={
              formik.touched.streetName ? formik.errors.streetName : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="country"
              name="country"
              disabled={formik.isSubmitting || !formEnabled}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country ? formik.errors.country : ""}
              sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
              label="Country"
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      maxHeight: 200, // Apply max height to the list
                    },
                  },
                  PaperProps: {
                    sx: {
                      maxHeight: 200, // Set max height for the dropdown
                      overflowY: "auto", // Enable scrolling
                    },
                  },
                },
              }}
            >
              {countries.map((c) => (
                <MenuItem key={c.isoCode} value={c.isoCode}>
                  {c.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="state"
              name="state"
              disabled={formik.isSubmitting || !formEnabled}
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              sx={{ "& .MuiOutlinedInput-root": { height: "54px" } }}
              helperText={formik.touched.state ? formik.errors.state : ""}
              label="State"
            >
              {states.map((s, index) => (
                <MenuItem key={`${s.isoCode}-${index}`} value={s.isoCode}>
                  {s.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="suburb"
            name="suburb"
            label="Suburb"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.suburb}
            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            helperText={formik.touched.suburb ? formik.errors.suburb : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="postCode"
            name="postCode"
            label="Post Code"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postCode}
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            helperText={formik.touched.postCode ? formik.errors.postCode : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contactPersonName"
            name="contactPersonName"
            label="Contact Person Name"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPersonName}
            error={
              formik.touched.contactPersonName &&
              Boolean(formik.errors.contactPersonName)
            }
            helperText={
              formik.touched.contactPersonName
                ? formik.errors.contactPersonName
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contactPersonEmail"
            name="contactPersonEmail"
            label="Contact Person Email"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPersonEmail}
            error={
              formik.touched.contactPersonEmail &&
              Boolean(formik.errors.contactPersonEmail)
            }
            helperText={
              formik.touched.contactPersonEmail
                ? formik.errors.contactPersonEmail
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contactPersonPhone"
            name="contactPersonPhone"
            label="Contact Person Phone"
            fullWidth
            margin="dense"
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPersonPhone}
            error={
              formik.touched.contactPersonPhone &&
              Boolean(formik.errors.contactPersonPhone)
            }
            helperText={
              formik.touched.contactPersonPhone
                ? formik.errors.contactPersonPhone
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          justifyContent: "space-between",
          padding: "10px 0 0",
        }}
      >
        <StyledButton
          loading={false}
          btnType={ButtonType.Secondary}
          disabled={formik.isSubmitting}
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          loading={false}
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </StyledButton>
      </Grid>
    </form>
  );
};
