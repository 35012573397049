import { Grid } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
  CollaboratorsActionCell,
  ProjectNameActionCell,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  ArchiveProjectIcon,
  EditProjectIcon,
  ReloadIcon,
  ViewProjectIcon,
} from "assets/constants";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import {
  isProjectOwner,
  ProjectLineItem,
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import { useState } from "react";

interface ProjectTableProps {
  loading: boolean;
  projects: ProjectLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  searchString: string;
  tabNumber: number;
  handleOpen: () => void;
  handleViewProject: (project: ProjectLineItem) => void;
  handleEditProject: (project: ProjectLineItem) => void;
  handleArchiveProject: (project: ProjectLineItem) => void;
}

export const ProjectTable = ({
  handleArchiveProject,
  handleEditProject,
  handleViewProject,
  loading,
  organisation,
  profile,
  projects,
  searchString,
  tabNumber,
}: ProjectTableProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedProject, setSelectedProject] =
    useState<ProjectLineItem | null>(null);

  const filteredProjects = projects
    .filter((p) =>
      p.projectName.toLowerCase().includes(searchString.toLowerCase())
    )
    .filter((p) => {
      return p.archived === Boolean(tabNumber);
    });

  const colDefs = [
    {
      cellRenderer: ProjectNameActionCell,
      field: "projectName",
      flex: 3,
      headerName: "Project Name",
      onCellClicked: (params) => handleViewProject(params.data),
    },
    {
      field: "projectDescription",
      flex: 3,
      headerName: "Description",
      onCellClicked: (params) => handleViewProject(params.data),
    },
    {
      field: "sector",
      flex: 2,
      headerName: "Sector",
      onCellClicked: (params) => handleViewProject(params.data),
      valueFormatter: (params) =>
        params.data.projectSector !== ProjectSector.Other
          ? projectSectorToString(params.data.projectSector)
          : params.data.projectSectorOther,
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: CollaboratorsActionCell,
      cellRendererParams: {
        paramKey: "collaborators",
      },
      cellStyle: centerCellStyles,
      field: "collaborators",
      flex: 3,
      headerClass: "centered-table-header",
      headerName: "Collaborators",
      onCellClicked: (params) => handleViewProject(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellStyle: rightCellStyles,
      field: "totalPermits",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Total Permits",
      onCellClicked: (params) => handleViewProject(params.data),
    },
    {
      cellClass: "center-ag-cell",

      cellStyle: rightCellStyles,
      field: "activePermits",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Active Permits",
      onCellClicked: (params) => handleViewProject(params.data),
    },
    {
      cellClass: "center-ag-cell",
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "action",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ] as (ColDef | ColGroupDef)[];

  const handleOpenConfirmDialog = async (project: ProjectLineItem) => {
    setSelectedProject(project);
    setOpenConfirmDialog(true);
  };

  const cellActions = [
    {
      action: handleViewProject,
      icon: <ViewProjectIcon />,
      title: "View Project",
    },
    {
      action: handleEditProject,
      icon: <EditProjectIcon />,
      title: "Edit Project",
      validateEnabled: (data: ProjectLineItem) => isProjectOwner(data, profile),
    },
    {
      action: handleOpenConfirmDialog,
      icon: !tabNumber ? <ArchiveProjectIcon /> : <ReloadIcon />,
      title: !tabNumber ? "Archive Project" : "Restore Project",
      validateEnabled: (data: ProjectLineItem) => {
        return isProjectOwner(data, profile);
      },
    },
  ] as CellAction[];

  return (
    <Grid item xs={12} md={12}>
      <Table
        columnDefs={colDefs}
        loading={loading}
        data={filteredProjects}
        context={{
          cellActions,
          handleRowClick: handleViewProject,
          organisation,
          profile,
        }}
      />
      <ConfirmationDialog
        message={
          !selectedProject?.archived
            ? "Are you sure you want to archive this project?"
            : "Are you sure you want to restore this project?"
        }
        open={openConfirmDialog}
        title={
          !selectedProject?.archived ? "Archive Project" : "Restore Project"
        }
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={async () => {
          selectedProject
            ? await handleArchiveProject(selectedProject)
            : undefined;
          setOpenConfirmDialog(false);
        }}
      />
    </Grid>
  );
};
