import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { ProjectSteps } from "@stories/molecules/ProjectSteps/ProjectSteps";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import {
  ProjectLineItem,
  createDefaultProject,
} from "permit-one-common/src/interfaces/project";
import { useState } from "react";

import { ProjectAddressForm } from "../ProjectForm/ProjectAddressForm";
import { ProjectDetailsForm } from "../ProjectForm/ProjectDetailsForm";

interface ProjectModalProps {
  open: boolean;
  organisation: OrganisationLineItem;
  existingProject?: ProjectLineItem;
  createProject: (projects: ProjectLineItem) => void;
  updateProject: (projects: ProjectLineItem) => void;
  handleClose: () => void;
}

export default function ProjectModal({
  createProject,
  existingProject,
  handleClose,
  open,
  organisation,
  updateProject,
}: ProjectModalProps) {
  const [project, setProject] = useState<ProjectLineItem>(
    existingProject || createDefaultProject(organisation)
  );
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (project: ProjectLineItem) => {
    setProject(project);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async (project: ProjectLineItem) => {
    if (existingProject) {
      await updateProject(project);
    } else {
      await createProject(project);
    }
    handleClose();
  };

  const selectedCreateProjectStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <ProjectDetailsForm project={project} handleSubmit={handleNext} />
        );
      case 1:
        return (
          <ProjectAddressForm
            project={project}
            handleSubmit={onSubmit}
            handleBack={handleBack}
          />
        );
    }
  })();

  const title = existingProject ? "Edit Project" : "Create Project";
  const loading = false;

  const handleCloseDialog = (event: any, reason: string) => {
    console.log(event, reason);
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return; // Prevent closing on backdrop click or Escape key
    }
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseDialog}
      className="bgBlueOverlay"
      disableEscapeKeyDown
    >
      <DialogTitle
        sx={{ fontSize: "20px !important", padding: "30px 34px 25px" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 20px 40px" }}>
        <Stack direction="column" spacing={2}>
          <ProjectSteps activeStep={activeStep} loading={loading} />
          {selectedCreateProjectStep}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
