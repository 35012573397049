import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useCondition } from "@hooks/crud/useCondition";
import { usePermit } from "@hooks/crud/usePermit";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import {
  Box,
  Grid,
  Typography,
  Chip,
  Stack,
  Tooltip,
  IconButton,
  Zoom,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import { ConditionControl } from "@stories/molecules/ConditionControls/ConditionControls";
import { ConditionCollaborators } from "@stories/organisms/ConditionCollaborators/ConditionCollaborators";
import { ConditionDocuments } from "@stories/organisms/ConditionDocuments/ConditionDocuments";
import { ConditionEventLog } from "@stories/organisms/ConditionEventLog/ConditionEventLog";
import { ConditionProperties } from "@stories/organisms/ConditionProperties/ConditionProperties";
import { ConditionTabs } from "@stories/organisms/ConditionTabs/ConditionTabs";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";
import * as RouteHelper from "@utils/routes";
import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { useCallback, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const getLastPathElement = (location: string): string => {
  const baseLocation = location.split("/");
  return baseLocation.pop() || "";
};

export const ViewCondition = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { conditionId, permitId } = useParams();
  const { isProjectLoading, selectedProject } = useProjectContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();
  const { isPermitLoading, selectedPermit } = usePermit(undefined, permitId);
  const { isConditionLoading, selectedCondition, updateCondition } =
    useCondition(undefined, undefined, conditionId);

  const [currentTab, setCurrentTab] = useState(0);

  const pathName = getLastPathElement(location.pathname);

  const handleUpdateCondition = useCallback(
    async (condition: ConditionLineItem) => {
      if (selectedPermit) {
        await updateCondition(selectedPermit, condition);
      }
    },
    [selectedPermit, updateCondition]
  );

  const isLoading =
    isProjectLoading ||
    isPermitLoading ||
    isConditionLoading ||
    isAuthProfileLoading;

  const isProjectOwner =
    userOrganisation &&
    selectedProject &&
    selectedProject.owner?.id === userOrganisation.id;
  const isPermitManager =
    userProfile &&
    selectedPermit &&
    selectedPermit.managers.map((pm) => pm.id).includes(userProfile.id);

  const canEdit = isProjectOwner || isPermitManager;
  const selectedConditionView = (() => {
    switch (pathName) {
      default:
      case "properties":
        return (
          <ConditionProperties
            loading={isLoading}
            canEdit={canEdit}
            project={selectedProject}
            permit={selectedPermit}
            condition={selectedCondition}
            handleUpdateCondition={handleUpdateCondition}
          />
        );
      case "documents":
        return (
          <ConditionDocuments
            isLoading={isLoading}
            project={selectedProject}
            permit={selectedPermit}
            condition={selectedCondition}
          />
        );
      case "mail":
        return (
          <MailCenter
            isLoading={isLoading}
            profile={userProfile}
            project={selectedProject}
            permit={selectedPermit}
            condition={selectedCondition}
            showDialog
          />
        );
      case "collaborators":
        return (
          <ConditionCollaborators
            loading={isLoading}
            condition={selectedCondition}
          />
        );
      case "event-log":
        return (
          <ConditionEventLog
            loading={isLoading}
            condition={selectedCondition}
          />
        );
    }
  })();

  const handleRequestReview = async () => {
    if (selectedProject && selectedPermit && selectedCondition && userProfile) {
      await updateCondition(selectedPermit, {
        ...selectedCondition,
        status: ConditionStatus.InReview,
      });
    }
  };

  const handleReject = async (rejectionMessage: string) => {
    if (selectedProject && selectedPermit && selectedCondition && userProfile) {
      await updateCondition(selectedPermit, {
        ...selectedCondition,
        messageData: rejectionMessage,
        status: ConditionStatus.Rejected,
      });
    }
  };

  const handleApproval = async () => {
    if (selectedProject && selectedPermit && selectedCondition && userProfile) {
      await updateCondition(selectedPermit, {
        ...selectedCondition,
        status: ConditionStatus.Approved,
      });
      // const mailThread = createDefaultMailThread(
      //   `${selectedCondition.conditionName} - Condition Approved`,
      //   `Condition approved for ${selectedCondition.conditionName}`,
      //   userProfile,
      //   [],
      //   selectedProject.id,
      //   selectedProject?.projectName,
      //   selectedPermit.id,
      //   selectedPermit?.permitName,
      //   selectedCondition.id,
      //   selectedCondition?.conditionName
      // );
      // await createMailThread(mailThread);
    }
  };

  const handlOnBack = () => {
    if (selectedProject && selectedPermit) {
      navigate(RouteHelper.conditions(selectedProject.id, selectedPermit.id));
    }
  };

  const loading = isProjectLoading || isPermitLoading || isConditionLoading;
  return (
    <Box>
      {isLoading ? (
        <Grid
          className="borderBottom"
          container
          spacing={2}
          sx={{
            alignItems: "center",
            display: "flex",
            margin: "0 0 40px",
            padding: "20px 40px 20px 35px",
            width: "100%",
          }}
        >
          <Grid item sx={{ padding: "0px !important" }} md={6}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton
                  size="large"
                  sx={{
                    color: "black",
                    height: "auto",
                    padding: "5px",
                    width: "auto",
                  }}
                >
                  <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  component="div"
                  sx={{ fontSize: "28px !important", fontWeight: "700" }}
                >
                  {"Permit Name"}
                </Typography>
              </Skeleton>
            </Stack>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "0px !important",
            }}
            md={6}
          >
            <Skeleton animation="wave" variant="rounded">
              <Chip label={"Status"} color={"success"} size="medium" />
            </Skeleton>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="borderBottom"
            container
            spacing={2}
            sx={{
              alignItems: "center",
              display: "flex",
              margin: "0 0 40px",
              padding: "20px 40px 20px 40px",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{ alignItems: "center", padding: "0px !important" }}
              md={6}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back"
                >
                  <IconButton
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    to={RouteHelper.permits(selectedProject?.id || "")}
                    className="visited-link"
                    style={{
                      color: "#00000",
                      fontSize: "22px",
                      textDecoration: "none",
                    }}
                  >
                    {selectedProject?.projectName}
                  </Link>
                  <Link
                    color="inherit"
                    to={RouteHelper.conditions(
                      selectedProject?.id || "",
                      selectedPermit?.id || ""
                    )}
                    className="visited-link"
                    style={{
                      color: "#00000",
                      fontSize: "22px",
                      textDecoration: "none",
                    }}
                  >
                    {selectedPermit?.permitName}
                  </Link>
                  <Typography color="text.primary">
                    {selectedCondition?.conditionName}
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0px !important",
              }}
              md={6}
            >
              <Chip
                label={
                  selectedCondition
                    ? conditionStatusToString(
                        selectedCondition.status,
                        Boolean(selectedCondition.markedAsNotApplicable)
                      )
                    : ""
                }
                sx={{
                  alignContent: "end",
                  backgroundColor: selectedCondition
                    ? conditionStatusToColor(
                        selectedCondition.status,
                        Boolean(selectedCondition.markedAsNotApplicable)
                      )
                    : "",
                  color: "black",
                  display: "flex",
                  padding: "0px !important",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2} sx={{ padding: "0 40px 40px" }}>
        <Grid item md={8}>
          <ConditionTabs
            loading={loading}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </Grid>
        <Grid item md={4}>
          <ConditionControl
            loading={loading}
            condition={selectedCondition}
            permit={selectedPermit}
            userProfile={userProfile}
            handleRequestReview={handleRequestReview}
            handleReject={handleReject}
            handleApproval={handleApproval}
          />
        </Grid>
        <Grid item md={12}>
          {selectedConditionView}
        </Grid>
      </Grid>
    </Box>
  );
};
