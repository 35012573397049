import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Checkbox,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import "react-quill/dist/quill.snow.css";

const AnimatedTableCell = motion(TableCell);

interface ConditionTableHeaderRowProps {
  locked: boolean;
  allSelected: boolean;
  readOnly: boolean;
  handleSelectAll: () => void;
}

export const ConditionTableHeaderRow = ({
  allSelected,
  handleSelectAll,
  locked,
  readOnly,
}: ConditionTableHeaderRowProps) => {
  return (
    <TableHead>
      <TableRow sx={{ height: "45px" }}>
        <AnimatePresence>
          {!locked ? (
            <AnimatedTableCell
              sx={{
                backgroundColor: "#fff",
                borderBottom: "2px solid rgba(241, 243, 244, 1)",
                borderTop: "2px solid rgba(241, 243, 244, 1)",
                padding: "0px 5px",
                textAlign: "center",
                width: "5%",
              }}
              layout // Enable layout transition
              align="center"
              component="th"
              initial={{ opacity: 0, x: -20 }} // Enter from the left
              animate={{ opacity: 1, x: 0 }} // Set to the default position
              exit={{ opacity: 0, x: -20 }} // Exit to the left
              transition={{
                damping: 25,
                stiffness: 200,
                type: "spring",
              }}
            >
              <Checkbox
                checked={allSelected}
                onChange={() => {
                  handleSelectAll();
                }}
              />
            </AnimatedTableCell>
          ) : null}
        </AnimatePresence>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            borderBottom: "2px solid rgba(241, 243, 244, 1)",
            borderTop: "2px solid rgba(241, 243, 244, 1)",
            maxWidth: "5%",
            padding: "0px 5px",
            width: "5%",
          }}
          align="center"
        >
          #
        </TableCell>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            borderBottom: "2px solid rgba(241, 243, 244, 1)",
            borderTop: "2px solid rgba(241, 243, 244, 1)",
            maxWidth: "30%",
            padding: "0px 5px",
            width: "30%",
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              fontFamily={"Inter, Sans-serif"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"16px"}
              color={"#717f76"}
            >
              Details
            </Typography>
            {readOnly ? null : locked ? (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  transform: "scale(0.7)",
                }}
              >
                <LockIcon />
              </Box>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  transform: "scale(0.7)",
                }}
              >
                <EditIcon />
              </Box>
            )}
          </Stack>
        </TableCell>
        <TableCell
          component="th"
          sx={{
            alignItems: "start",
            backgroundColor: "#fff",
            borderBottom: "2px solid rgba(241, 243, 244, 1)",
            borderTop: "2px solid rgba(241, 243, 244, 1)",
            maxWidth: "30%",
            padding: "0px 5px",
            width: "30%",
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              fontFamily={"Inter, Sans-serif"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"16px"}
              color={"#717f76"}
            >
              Comments
            </Typography>
            {locked ? (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  transform: "scale(0.7)",
                }}
              >
                <LockIcon />
              </Box>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  transform: "scale(0.7)",
                }}
              >
                <EditIcon />
              </Box>
            )}
          </Stack>
        </TableCell>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            borderBottom: "2px solid rgba(241, 243, 244, 1)",
            borderTop: "2px solid rgba(241, 243, 244, 1)",
            maxWidth: "20%",
            padding: "0px 5px",
            width: "10%",
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Typography
              fontFamily={"Inter, Sans-serif"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"16px"}
              color={"#717f76"}
            >
              Discipline
            </Typography>
            {locked ? (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  transform: "scale(0.7)",
                }}
              >
                <LockIcon />
              </Box>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  transform: "scale(0.7)",
                }}
              >
                <EditIcon />
              </Box>
            )}
          </Stack>
        </TableCell>
        <TableCell
          component="th"
          sx={{
            backgroundColor: "#fff",
            borderBottom: "2px solid rgba(241, 243, 244, 1)",
            borderTop: "2px solid rgba(241, 243, 244, 1)",
            maxWidth: "10%",
            padding: "0px 5px",
            width: "10%",
          }}
          align="center"
        >
          <Typography
            fontFamily={"Inter, Sans-serif"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"16px"}
            color={"#717f76"}
          >
            Status
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: "#fff",
            borderBottom: "2px solid rgba(241, 243, 244, 1)",
            borderTop: "2px solid rgba(241, 243, 244, 1)",
            maxWidth: "5%",
            padding: "0px 5px",
            width: "5%",
          }}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
};
