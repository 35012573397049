import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import {
  decodePermit,
  encodePermit,
} from "permit-one-common/src/decoders/permit";
import {
  Permit,
  PermitLineItem,
} from "permit-one-common/src/interfaces/permit";
import { useCallback, useEffect, useState } from "react";

export const usePermit = (projectId?: string, permitId?: string) => {
  const [selectedPermit, setSelectedPermit] = useState<PermitLineItem>();
  const [permits, setPermits] = useState<PermitLineItem[]>([]);
  const [isPermitLoading, setIsPermitLoading] = useState<boolean>(true);
  const [isPermitUpdating, setIsPermitUpdating] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const getProfileForPermit = useCallback(
    async (p: Permit): Promise<PermitLineItem> => {
      return decodePermit(p, getImageUrl);
    },
    []
  );

  const getPermit = useCallback(
    async (id: string, isUpdate = false): Promise<void> => {
      try {
        if (isUpdate) {
          setIsPermitUpdating(true);
        } else {
          setIsPermitLoading(true);
        }
        const permitResponse = (await getApiData("getPermit", "permit", id))
          .data as Permit;
        if (permitResponse) {
          const permitLineItem = await getProfileForPermit(permitResponse);
          setSelectedPermit(permitLineItem);
        } else {
          setSelectedPermit(undefined);
        }
      } catch (e: any) {
        setError("Could not fetch permit");
      } finally {
        if (isUpdate) {
          setIsPermitUpdating(false);
        } else {
          setIsPermitLoading(false);
        }
      }
    },
    [getProfileForPermit]
  );

  const listPermits = useCallback(
    async (projectId: string): Promise<void> => {
      try {
        setIsPermitLoading(true);
        const permits = await listApiData("listPermits", "permit", projectId);
        const permitLineItems = await Promise.all(
          permits.data.map((p) => getProfileForPermit(p as Permit))
        );
        setPermits(permitLineItems);
      } catch (e: any) {
        setError("Could not list permits");
      } finally {
        setIsPermitLoading(false);
      }
    },
    [getProfileForPermit]
  );

  const createPermit = useCallback(
    async (permit: PermitLineItem): Promise<void> => {
      try {
        setIsPermitLoading(true);
        await postApiData("createPermit", "permit", encodePermit(permit));
        setPermits((prevPermits) => [...prevPermits, permit]);
      } catch (e: any) {
        setError("Could not list permits");
      } finally {
        setIsPermitLoading(false);
      }
    },
    []
  );

  const updatePermit = useCallback(
    async (permit: PermitLineItem): Promise<void> => {
      try {
        setIsPermitLoading(true);
        await postApiData("updatePermit", "permit", encodePermit(permit));
        setPermits((prevPermits) => {
          return prevPermits.map((c) => (c.id === permit.id ? permit : c));
        });
        if (selectedPermit?.id === permit.id) {
          setSelectedPermit(permit);
        }
      } catch (e: any) {
        setError("Could not update permit");
      } finally {
        setIsPermitLoading(false);
      }
    },
    [selectedPermit, permits]
  );

  const deletePermit = useCallback(
    async (permit: PermitLineItem): Promise<void> => {
      try {
        setIsPermitLoading(true);
        await postApiData("deletePermit", "permit", encodePermit(permit));
        setPermits((prevPermits) =>
          prevPermits.filter((p) => p.id !== permit.id)
        );
        if (selectedPermit?.id === permit.id) {
          setSelectedPermit(undefined);
        }
      } catch (e: any) {
        setError("Could not delete permit");
      } finally {
        setIsPermitLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (permitId) {
      setIsPermitLoading(false);
    } else if (projectId) {
      listPermits(projectId);
    }
  }, [projectId, listPermits]);

  useEffect(() => {
    if (permitId) {
      getPermit(permitId);
    }
  }, [permitId, getPermit]);

  return {
    createPermit,
    deletePermit,
    error,
    getPermit,
    isPermitLoading,
    isPermitUpdating,
    listPermits,
    permits,
    selectedPermit,
    updatePermit,
  };
};
